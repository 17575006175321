import * as mutationTypes from "./types/mutations";
import { DEFAULT_STORAGE } from "@/mixins/constants";

export default {
  [mutationTypes.SET_DETAILS_STATUS]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("tracker-data") !== null) {
      data = JSON.parse(localStorage.getItem("tracker-data"));
    }
    data.detailsStatus = value;
    localStorage.setItem("tracker-data", JSON.stringify(data));

    state.detailsStatus = value;
  },
};
