import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/plugins/vuetify";
import "./assets/styles/common.scss";
import store from "./store";
import "./utils/translations";
import router from "@/router";
import VueOffline from "vue-offline";
import avatarComponent from "@/components/UI/t-avatar.vue";
import imgComponent from "@/components/UI/t-img.vue";
import tagComponent from "@/components/UI/t-tag.vue";
import TimeTask from "@/components/UI/TimeTask.vue";
import loaderMixin from "@/mixins/loader";
import draggable from "vuedraggable";
import VObserver from "@/directives/VObserver";


Vue.use(VueOffline);

Vue.config.productionTip = false;

Vue.prototype.$dialogTransition = "slide-y-transition";

Vue.prototype.$getChangedData = function (modyData, copyData) {
  const copyDataKeys = Object.keys(copyData);
  const res = [];
  copyDataKeys.forEach((key) => {
    if (copyData[key] !== modyData[key]) {
      res.push({ field: key, value: modyData[key] });
    }
  });
  return res;
};

Vue.component("t-avatar", avatarComponent);
Vue.component("t-img", imgComponent);
Vue.component("t-tag", tagComponent);
Vue.component("task-time", TimeTask);
Vue.component("draggable", draggable);
Vue.directive("observer", VObserver);
Vue.mixin(loaderMixin);

const app = new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
});

await Vue.initI18nManager();
app.$mount("#app");
