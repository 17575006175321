import _ from "lodash";
import EventBus from "@/events/EventBus";

export default {
  data: () => ({
    image: null,
    T_IMG_OBJECT: {},
    T_IMG_COPY_OBJECT: {},
    T_IMG_MEDIA_KEY: "T_IMG_MEDIA_KEY",
  }),
  methods: {
    changeImage(image) {
      this.image = image;
      this.T_IMG_OBJECT[this.T_IMG_MEDIA_KEY] = this.image;
    },
    editChangeImage(image) {
      this.image = image;
      this.T_IMG_OBJECT[this.T_IMG_MEDIA_KEY] = this.image;
      this.T_IMG_COPY_OBJECT[this.T_IMG_MEDIA_KEY] = this.image;
    },
    resetImage() {
      this.image = null;
      this.T_IMG_OBJECT[this.T_IMG_MEDIA_KEY] = null;
    },
    pastedImage(file) {
      EventBus.$emit("pasted-image", file);
    },
  },
  watch: {
    image: {
      deep: true,
      handler() {
        if (this.image) {
          this.T_IMG_OBJECT[this.T_IMG_MEDIA_KEY] = this.image;
        } else {
          this.T_IMG_OBJECT[this.T_IMG_MEDIA_KEY] = null;
        }
        this.changed = !_.isEqual(this.T_IMG_COPY_OBJECT, this.T_IMG_OBJECT);
      },
    },
  },
};
