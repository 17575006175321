<template>
  <div>
    <div>
      <Header />
    </div>
    <div
      class="content-block"
      style="min-height: 90vh"
      :style="menuPosition ? $vuetify.breakpoint.width <= 666 ? 'padding-left: 0px':'padding-left: 236px;' : 'padding-left: 0px;'"
    >
      <div class="content-block_wrap">
        <slot></slot>
        <div class="scroll-up__wrapper">
          <v-fab-transition>
            <VBtn
              v-if="isShowUpBtn"
              color="primary"
              small
              absolute
              bottom
              right
              fab
            >
              <VIcon>mdi-chevron-up</VIcon>
            </VBtn>
          </v-fab-transition>
        </div>
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import NavigationDrawer from "@/components/layout/navigationDrawer.vue";
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";
import theme from "@/mixins/theme";
import localStorageMixin from "@/mixins/localStorage";
import Loader from "@/components/layout/Loader.vue";

export default {
  name: "AppLayout",
  mixins: [theme, localStorageMixin],
  components: {
    NavigationDrawer,
    Header,
    Loader,
  },
  data: () => ({
    isShowUpBtn: false,
    online: true,
    showOnlineBackLine: false,
  }),
  async mounted() {
    this.online = this.isOnline;
    try {
      this.onChangeSelectedTheme(this.getStorageItem("theme"));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  },

  watch: {
    isOnline: {
      deep: true,
      handler() {
        if (this.isOnline && this.online === false) {
          this.showOnlineBackLine = true;
          document.location.reload();
        } else {
          this.online = this.isOnline;
        }
      },
    },
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    ...mapGetters("auth", {
      menuPosition: getterTypes.GET_MENU_POSITION,
    }),
  },
};
</script>

<style lang="scss">
.content-block {
  display: block;
  transition: all 0.2s;
}
.content-block_wrap {
  scroll-behavior: smooth;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 56px);
  max-height: 100%;
  padding: 4px 8px;
}
.listGroupMenu {
  .v-list-group__items {
    padding-left: 18px;
    border-left: 3px solid #ff7a00;
  }
}

.v-navigation-drawer--mini-variant {
  .listGroupMenu {
    .v-list-group__items {
      padding-left: 0;
      border-left: 3px solid;
      margin-left: -3px;
    }
  }
}

.active-orange--text {
  color: var(--v-orange-base) !important;

  svg {
    fill: var(--v-orange-base) !important;
  }
}
</style>
