import EventBus from "@/events/EventBus";

export default {
  methods: {
    initPaste() {
      window.addEventListener("paste", this.onPaste);
    },
    async onPaste(event) {
      if (event.clipboardData && event.clipboardData.files.length > 0) {
        const file = event.clipboardData.files[0];
        const reader = new FileReader();
        const allowedImageTypes = [
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/gif",
        ];
        if (
          this.inputTask.cards_media == null &&
          allowedImageTypes.includes(file.type)
        ) {
          reader.onload = (e) => {
            this.changeTaskImage(file);
          };
          reader.readAsDataURL(file);
        } else if (this.type === "edit") {
          reader.onload = (e) => {
            EventBus.$emit("attachment-pasted", file);
          };
          reader.readAsDataURL(file);
        }
      }
    },
    selectCoverImage(item) {
      this.task.cards_media = item;
    },
  },
};
