import * as actionTypes from "./types/actions";
import * as mutationTypes from "./types/mutations";

export default {
  [actionTypes.SET_ACTIVE_LIST]: ({ commit }, value = 'all') => {
    commit(mutationTypes.SET_ACTIVE_LIST, value);
  },
  [actionTypes.SET_ACTIVE_LIST_TAB]: ({ commit }, value = 0) => {
    commit(mutationTypes.SET_ACTIVE_LIST_TAB, value);
  },
};
