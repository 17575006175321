<template>
  <v-dialog
      v-model="visibility"
      max-width="800"
      content-class="cross__dialog"
      :transition="$dialogTransition"
      @click:outside="showConfirmDialog"
      scrollable
      :persistent="changed"
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="showConfirmDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-text
          class="pt-5"
          style="overflow-y: auto; max-height: 740px; scroll-behavior: smooth"
      >
        <v-row
        >
          <v-col cols="12"
          >
            <t-img
                @success="changeImage"
                @reset="resetImage"
                @editChangeImage="editChangeImage"
                :customWidth="750"
            />
          </v-col
          >
        </v-row>
        <v-divider/>
        <v-row no-gutters class="my-2">
          <v-col cols="12" class="pa-2">
            <v-row class="d-flex align-center">
              <v-col cols="auto" class="px-1">
                <v-btn
                    text
                    :disabled="type === 'create'"
                    @click="copyToClipboard(inputTask.number)"
                    class="px-1"
                >
                  <v-icon class="mr-1">mdi-card-text</v-icon>
                  <span> #{{ type === "edit" ? inputTask.number : "" }}</span>
                </v-btn>
              </v-col>
              <v-col cols="auto" class="name-textfield" style="flex-grow: 1">
                <v-textarea
                    solo
                    style="transition: border 0.2s; border: 2px solid #ff7a00"
                    color="orange"
                    dense
                    :placeholder="$t('form.title')"
                    rows="1"
                    hide-details
                    :maxlength="225"
                    v-model="inputTask.name"
                    @input="handleInput"
                    @keydown="handleKeyDown"
                    auto-grow
                    ref="inputMsg"
                >
                  <template v-slot:append>
                    <v-scroll-x-transition>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              icon
                              class="copy-btn ml-3"
                              @click="copyToClipboard(inputTask.name)"
                              v-bind="attrs"
                              v-on="on"
                              :disabled="!inputTask.name"
                          >
                            <v-icon
                                color="orange"
                                :size="25"
                                transition="scale-transition"
                            >
                              mdi-content-copy
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("table.copy") }}</span>
                      </v-tooltip>
                    </v-scroll-x-transition>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pa-2">
            <v-row class="d-flex align-center justify-content-space-between">
              <v-col cols="auto" class="task-desc-title">
                {{ $t("boards.in_list") }}
                <span
                    :style="selectedListText ? 'text-decoration: underline' : ''"
                    class="font-weight-medium"
                >{{ selectedListText || "--" }}</span
                >
              </v-col>
              <v-col cols="auto" class="pl-0">
                <v-menu
                    bottom
                    transition="fade-transition"
                    :close-on-content-click="false"
                    offset-y
                    nudge-bottom="2"
                    ref="moveMenu"
                    v-model="moveMenuVisible"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="btn-orange-bg"
                        style="height: 30px"
                        block
                        v-bind="attrs"
                        v-on="on"
                    >
                      <span>{{ $t("boards.move") }}</span>
                      <v-icon size="20">mdi-arrow-right</v-icon>
                    </v-btn>
                  </template
                  >
                  <v-card class="py-3" width="220"
                  >
                    <v-row class="px-2"
                    >
                      <v-col cols="12"
                      >
                        <v-select
                            :items="activeLists"
                            item-text="name"
                            item-value="id"
                            hide-details
                            outlined
                            dense
                            v-model="inputTask.list_id"
                            item-color="orange"
                            :label="$t('table.list')"
                            color="orange"
                            @change="onMoveList()"
                            :disabled="!moveMenuVisible"
                        ></v-select
                        >
                      </v-col>
                      <v-col cols="12" v-if="type === 'edit'"
                      >
                        <v-select
                            :items="boardsList"
                            item-text="name"
                            item-value="id"
                            hide-details
                            outlined
                            dense
                            v-model="chosenBoard"
                            item-color="orange"
                            :label="$t('boards.board')"
                            color="orange"
                            @change="onChangeBoard(chosenBoard)"
                            :disabled="!moveMenuVisible"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card
                  >
                </v-menu>
              </v-col>
              <v-col cols="7" class="pl-8">
                <v-row>
                  <v-col cols="8"
                  >
                    <v-select
                        :items="parentsCards"
                        item-text="name"
                        item-value="id"
                        hide-details
                        outlined
                        dense
                        v-model="chosenParentCard"
                        item-color="orange"
                        :label="$t('form.taskDescriptionDialog.ParentCards')"
                        color="orange"
                        @change="onChangeParentCard(chosenParentCard)"
                    ></v-select>
                  </v-col>
                  <v-col cols="2"
                         class="d-flex align-center"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-checkbox
                          color="whrite"
                          :disabled="!inputTask.name" 
                          v-model="isParentBoolean"
                          :value="isParentBoolean"
                          v-on="on"> <!-- :false-value="0" :true-value="1" -->
                        </v-checkbox>
                      </template>
                      <span>{{$t('form.taskDescriptionDialog.ParentCard')}}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider/>
        <v-row no-gutters class="my-2">
          <v-row>
            <v-col cols="auto" class="pa-2">
              <v-col class="pb-0 task-desc-title">
                {{ $t("form.taskDescriptionDialog.users") }}
              </v-col>
              <v-col>
                <t-avatar
                    :canDelete="type === 'edit' ? true : false"
                    v-for="user in inputTask.assigned_users"
                    :key="user.id"
                    :user="user"
                    @deleteFromCard="deleteFromCard"
                />
                <v-btn icon class="btn-add-user" @click="usersCard = true">
                  <v-icon size="20">mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-col>
            <v-col cols="auto" class="pa-2">
              <v-col class="pb-0 task-desc-title">
                {{ $t("form.taskDescriptionDialog.tags") }}
              </v-col>
              <v-col cols="auto">
                <t-tag
                    v-for="label in inputTask.assigned_labels"
                    :key="label.id"
                    :tag="{ name: label.name, bg: label.color }"
                />
                <v-btn
                    icon
                    class="mb-1 btn-add-label"
                    @click="labelsDialog = true"
                >
                  <v-icon size="20">mdi-plus</v-icon>
                </v-btn
                >
              </v-col>
            </v-col>
            <v-col cols="auto" class="pa-2">
              <v-col class="task-desc-title pb-2">
                {{ $t("form.taskDescriptionDialog.status") }}
              </v-col>
              <v-col
                  cols="auto"
                  class="checkbox-isDone-wrapper"
                  :class="
                  inputTask.status ? 'checkbox-isDone-wrapper--active' : ''
                "
              >
                <v-checkbox
                    v-model="inputTask.status"
                    :disabled="type === 'create'"
                    :false-value="0"
                    :true-value="1"
                    color="success"
                    hide-details
                    class="pl-1 pt-0"
                    :class="
                    inputTask.status === 1 ? 'checkbox-isDone--active' : ''
                  "
                    :label="$t('boards.is_done')"
                />
              </v-col>
            </v-col>
            <v-col cols="auto" class="pa-2">
              <v-col class="pb-0 task-desc-title">
                {{ $t("table.priority") }}
              </v-col>
              <v-col class="pt-0 mt-2">
                <v-select
                    outlined
                    dense
                    hide-details
                    :items="priorities"
                    style="width: 90px; max-width: 90px;"
                    v-model="inputTask.priority"
                    item-color="orange"
                    class="select-priority--card"
                    :color="$vuetify.theme.dark ? '#CCC' : '#000'"
                >
                  <template slot="item" slot-scope="{ item }">
                    <div class="d-flex align-center">
                      <span
                          :style="`background-color: ${priorityColor(item)}`"
                          style="
                          height: 12px;
                          width: 12px;
                          border-radius: 5px;
                          border: thin solid #757575;
                        "
                          class="mr-1"
                      ></span>
                      {{ item }}
                    </div>
                  </template>
                  <template slot="selection" slot-scope="{ item }">
                    <span
                      :style="`background-color: ${priorityColor(item)}`"
                      style="
                        height: 12px;
                        width: 12px;
                        border-radius: 5px;
                        border: thin solid #757575;
                        "
                          class="mr-1"
                      ></span>
                    {{ item }}
                  </template>
                </v-select
                >
              </v-col>
            </v-col>
            <v-col cols="auto" class="pa-2">
              <v-col class="pb-0 task-desc-title">
                {{ $t("form.taskDescriptionDialog.SelectTestCaseTitle") }}
              </v-col>
              <v-col class="pt-0 mt-2">
                <v-btn class="btn-orange-bg" @click="openChecklistDialog">
                  {{ $t("form.taskDescriptionDialog.openList") }}
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-row>
        <v-divider/>
        <v-row no-gutters class="my-2">
          <v-row>
            <v-col cols="auto" class="pa-2">
              <v-col class="pb-0 task-desc-title">
                {{ $t("table.type") }}
              </v-col>
              <v-col class="pt-0 mt-2">
                <v-btn
                    @click="changeTaskType(inputTask)"
                    outlined
                    :color="inputTask.type == 'Bug' ? 'error' : 'success'"
                    height="40px"
                    style="width: 150px; min-width: 150px"
                    :class="
                    inputTask.type == 'Bug'
                      ? 'v-custom-tonal-btn--error'
                      : 'v-custom-tonal-btn--success'
                  "
                >
                  <v-icon class="mr-1">
                    {{
                      inputTask.type == "Bug"
                          ? "mdi-bug"
                          : "mdi-clipboard-check-outline"
                    }}
                  </v-icon
                  >
                  {{
                    inputTask.type == "Bug" ? $t("table.bug") : $t("table.task")
                  }}
                </v-btn>
              </v-col>
            </v-col>
            <v-col cols="auto" class="pa-2">
              <v-col class="pb-0 task-desc-title">
                {{ $t("form.taskDescriptionDialog.start_date") }}
              </v-col>
              <v-col class="pt-0 mt-2">
                <VMenu
                    ref="menuFirstDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="text-caption"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        outlined
                        readonly
                        append-icon="mdi-calendar"
                        color="orange"
                        dense
                        style="max-width: 140px; width: 140px"
                        hide-details
                        v-model="inputTask.start_date"
                        v-bind="attrs"
                        v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                      :locale="currentLanguage.key"
                      color="orange"
                      v-model="inputTask.start_date"
                      no-title
                      scrollable
                      min="2024"
                      :max="inputTask.end_date || new Date(
                        new Date().getFullYear() + 1,
                        new Date().getMonth(),
                        new Date().getDate()
                      ).toISOString().substr(0, 10)"
                  />
                </VMenu>
              </v-col>
            </v-col>
            <v-col cols="auto" class="pa-2">
              <v-col class="pb-0 task-desc-title">
                {{ $t("form.taskDescriptionDialog.finish_date") }}
              </v-col>
              <v-col class="pt-0 mt-2">
                <VMenu
                    ref="menuFirstDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="text-caption"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        outlined
                        readonly
                        append-icon="mdi-calendar"
                        color="orange"
                        dense
                        style="max-width: 140px; width: 140px"
                        hide-details
                        v-model="inputTask.end_date"
                        v-bind="attrs"
                        v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                      :locale="currentLanguage.key"
                      color="orange"
                      v-model="inputTask.end_date"
                      no-title
                      scrollable
                      :min="inputTask.start_date"
                      :max="new Date(
                        new Date().getFullYear() + 1,
                        new Date().getMonth(),
                        new Date().getDate()
                      ).toISOString().substr(0, 10)"
                  />
                </VMenu>
              </v-col>
            </v-col>
            <v-col cols="auto" class="pa-2">
              <v-col class="pb-0 task-desc-title">
                {{ $t("form.taskDescriptionDialog.story_points") }}
              </v-col>
              <v-col class="pt-0 mt-2">
                <v-select
                    :items="storyPointsList"
                    item-text="text"
                    item-value="value"
                    hide-details
                    outlined
                    dense
                    v-model="inputTask.story_points"
                    item-color="orange"
                    color="orange"
                    style="max-width: 75px; width: 75px"
                ></v-select>
              </v-col>
            </v-col>
            <v-col cols="auto" class="ml-3 pa-2 pb-6 d-flex align-end" v-if="this.type != 'create'">
              <v-btn class="btn-orange-bg" @click="timePickerDialog = true">{{
                  $t('btn.addTime')
                }}</v-btn>
            </v-col>
            <!-- <v-col cols="auto">
              <task-time
                :timeHours="timeHours"
                :timeMinutes="timeMinutes"
              ></task-time>
            </v-col> -->
          </v-row>
        </v-row>
        <v-divider/>
        <v-row no-gutters class="my-2">
          <v-col cols="12" class="pa-2 task-desc-title">
            <v-row class="d-flex align-center">
              <v-col cols="auto" class="pr-1">
                <v-icon :color="$vuetify.theme.dark ? '#F8F8FF' : '#1E1E1E'"
                >mdi-image-text
                </v-icon
                >
              </v-col>
              <v-col
                  cols="auto"
                  class="pl-0"
                  :style="{ color: $vuetify.theme.dark ? '#F8F8FF' : '#1E1E1E' }"
              >
                {{ $t("form.taskDescriptionDialog.Description") }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-2">
          <v-col cols="12" class="pt-0">
            <v-textarea
                outlined
                :label="$t('form.description')"
                dense
                v-model="inputTask.description"
                hide-details="auto"
                rows="4"
                auto-grow
                :maxlength="1000"
                color="orange"
            />
          </v-col>
        </v-row>

        <attachmentsDialog
            v-if="attachmentsVisible"
            :board="board"
            :users="users"
            :inputTask="inputTask"
            @changeTask="changeTask"
            @setCardLoading="setCardLoading"
            @selectCoverImage="selectCoverImage"
            @fileAdded="onFileAdded"
        />
        <!-- <v-row
          justify="space-between"
          align="center"
          v-if="type === 'edit'"
          ref="scrollTargetMessage"
          class="mt-0"
        >
          <v-col cols="auto" class="d-flex align-center pl-4">
            <v-icon
                class="pr-1"
                :color="$vuetify.theme.dark ? '#F8F8FF' : '#1E1E1E'"
            >mdi-menu
            </v-icon
            >
            <span class="task-desc-title">{{
                $t("form.taskDescriptionDialog.Activity")
              }}</span>
          </v-col>
          <v-col cols="auto">
            <v-btn
                class="v-custom-tonal-btn--orange"
                outlined
                @click="handleActivityClick"
            >{{
                activitiesVisible
                    ? $t("form.taskDescriptionDialog.HideDetails")
                    : $t("form.taskDescriptionDialog.ShowDetails")
              }}
            </v-btn
            >
          </v-col>
        </v-row> -->

        <v-row align="center" v-if="type === 'edit'">
          <v-col class="d-flex align-center ma-0 pa-0 ml-3">
            <v-col cols="auto" class="ma-0 pl-0 pr-1"
            >
              <t-avatar :user="getUser"
              />
            </v-col>
            <v-col cols="auto" class="pa-0 pl-1" style="flex-grow: 1">
              <v-text-field
                  v-model="activityNewMessage"
                  @keyup.enter="activityAddMessage"
                  :placeholder="$t('form.taskDescriptionDialog.PostComment')"
                  dense
                  class="activity-message-field"
                  outlined
                  style="font-size: 14px"
                  hide-details
                  color="orange"
                  :maxLength="225"
              >
                <template v-slot:append-outer>
                  <v-scroll-x-transition>
                    <v-btn
                        icon
                        style="position: relative; bottom: 2px; right: 6px"
                        :disabled="activityNewMessage.length === 0"
                        @click="activityAddMessage()"
                    >
                      <v-icon color="orange" transition="scale-transition">
                        mdi-send
                      </v-icon>
                    </v-btn>
                  </v-scroll-x-transition>
                </template>
              </v-text-field>
            </v-col>
          </v-col>
        </v-row>
        <v-col cols="auto" v-if="this.type != 'create'">
          <v-btn
              class="v-custom-tonal-btn--orange"
              outlined
              block
              small
              @click="handleActivityClick"
          >{{
              activitiesVisible
                  ? $t("form.taskDescriptionDialog.HideDetails")
                  : $t("form.taskDescriptionDialog.ShowDetails")
            }}
          </v-btn
          >
        </v-col>
        <v-row v-if="activitiesVisible && activitiesList.length">
          <v-col
              cols="12"
              class="pt-0 pb-0"
              v-for="(item, index) in activitiesList"
              :key="index"
              :ref="index === 0 ? 'firstActivity' : null"
          >
            <v-col
                v-if="item.type == 'history'"
                cols="auto"
                class="d-flex align-center pb-0 px-0 pt-2"
            >
              <t-avatar :user="item.user"/>
              <span class="px-2 font-weight-bold font-14">{{
                  item.username
                }}</span>
              <span class="font-weight-medium">{{ item.date }}</span>
            </v-col>
            <v-col v-if="item.type == 'history'" cols="12" class="px-10 py-0">
              <div
                  class="font-weight-bold"
                  :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
              >
                {{ item.description }}
              </div>
            </v-col>
            <v-col
                v-if="item.type == 'comment'"
                cols="auto"
                class="d-flex align-center pb-0 px-0 pt-2"
            >
              <t-avatar :user="item.user"/>
              <span class="px-2 font-weight-bold font-14">{{
                  item.username
                }}</span>
              <span class="font-weight-medium">{{ item.date }}</span>
            </v-col>
            <v-col
                v-if="item.type == 'comment'"
                cols="12"
                class="pl-10 pr-0 py-0"
            >
              <v-text-field
                  v-if="item.user.id === getUser.id && item.editStatus"
                  v-model="item.description"
                  dense
                  solo
                  class="activity-comment-field"
                  hide-details
                  @keyup.enter="saveChangedComment(item)"
              >
                <template v-slot:append v-if="item.editStatus">
                  <v-scroll-x-transition>
                    <v-btn
                        icon
                        :disabled="!item.description.length"
                        @click="saveChangedComment(item)"
                        small
                    >
                      <v-icon
                          :color="$vuetify.theme.dark ? '#F8F8FF' : '#1E1E1E'"
                      >
                        mdi-check-circle-outline
                      </v-icon>
                    </v-btn>
                  </v-scroll-x-transition>
                </template>
              </v-text-field
              >
              <div v-else class="activityComment">{{ item.description }}</div>
            </v-col>
            <v-col
                v-if="item.type == 'comment' && item.user.id === getUser.id"
                cols="auto"
                class="ma-0 pa-0 ml-10 mt-1"
            >
              <v-btn
                  small
                  elevation="0"
                  class="v-custom-tonal-btn--orange px-1"
                  style="text-transform: none"
                  @click="editComment(item)"
                  :disabled="item.editStatus"
              >
                <v-icon small class="mr-1">mdi-pencil
                </v-icon
                >
                {{ $t("btn.edit") }}
              </v-btn
              >
              <v-btn
                  small
                  class="px-1 ml-1 v-custom-tonal-btn--error"
                  style="text-transform: none"
                  elevation="0"
                  @click="
                  deleteCommentItem = item;
                  deleteCommentVisible = true;
                "
                  :loading="deleteCommentVisible"
              >
                <v-icon small class="mr-1">mdi-pencil-remove
                </v-icon
                >
                {{ $t("btn.delete") }}
              </v-btn
              >
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
          class="px-3 py-1"
          :style="
          $vuetify.theme.dark
            ? 'border-top: thin solid rgba(255, 255, 255, 0.12);'
            : 'border-top: thin solid rgba(0, 0, 0, 0.12)'
        "
      >
        <v-col cols="auto" class="pr-0 py-1" v-if="type === 'edit'">
          <v-btn
              class="btn-edit"
              color="error"
              @click="confirmArchive = true"
              style="width: 32px !important; height: 32px !important"
          >
            <v-icon class="text-white" size="24"
            >mdi-archive-outline
            </v-icon
            >
          </v-btn
          >
        </v-col>
        <v-col cols="auto" style="flex-grow: 1" class="py-1">
          <v-btn
              v-if="type === 'create'"
              @click="CheckCreate()"
              :disabled="!changed"
              block
              class="btn-orange-bg"
              :loading="loading"
          >{{ $t("btn.create") }}
          </v-btn
          >
          <v-btn
              v-else-if="type === 'edit'"
              @click="onUpdate()"
              :disabled="!changed"
              block
              class="btn-orange-bg"
              :loading="loading"
          >{{ $t("btn.update") }}
          </v-btn
          >
        </v-col>
      </v-card-actions>
    </v-card>
    <checklist-dialog
        v-if="checklistDialogVisible"
        :visible="checklistDialogVisible"
        :type="type"
        :task="task"
        @close="checklistDialogVisible = false"
    />
    <stepsDialog
        v-if="stepsDialog"
        :visible="stepsDialog"
        @close="stepsDialog = false"
    />
    <timeTrackerDialog
        v-if="timePickerDialog"
        :visible="timePickerDialog"
        :board="board"
        :users="users"
        :inputTask="inputTask"
        :type="'card'"
        @close="timePickerDialog = false"
    />
    <usersCard
        v-if="usersCard"
        :visible="usersCard"
        :boardUsers="users"
        :task="inputTask"
        :board="board"
        :type="type"
        @close="usersCardClose"
        @deleteUserFromCard="deleteUserFromCard"
        @addUserToCard="addUserToCard"
    />
    <labelsListDialog
        v-if="labelsDialog"
        :visible="labelsDialog"
        @close="labelsClose"
        :board="board"
        :projectId="copiedUuid"
        :task="inputTask"
        :type="type"
        :key="labels.length"
        @addLabelToCard="addLabelToCard"
        @deleteLabelFromCard="deleteLabelFromCard"
    />
    <ConfirmLeftDialog
        :visible="visibleConfirm"
        @close="visibleConfirm = false"
        @delete="onCancel"
    />
    <ConfirmDialog
        v-if="confirmArchive"
        :visible="confirmArchive"
        @close="confirmArchive = false"
        @onConfirm="onArchive()"
        @onCancel="confirmArchive = false"
        :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("form.taskDescriptionDialog.archive_title") }}
      </template>
      <template v-slot:description>
        {{ $t("form.taskDescriptionDialog.archive_desc") }}
      </template>
    </ConfirmDialog
    >
    <ConfirmDialog
        v-if="deleteCommentVisible"
        :visible="deleteCommentVisible"
        @close="deleteCommentVisible = false"
        @onConfirm="activityDeleteMessage()"
        @onCancel="deleteCommentVisible = false"
        :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("form.deleteCardDialog.delete_dialog_title") }}
      </template>
      <template v-slot:description>
        {{ $t("form.deleteCardDialog.delete_dialog_desc") }}
      </template>
    </ConfirmDialog
    >
    <CreateMultiTasksDialog
        v-if="createmulti"
        :visible="createmulti"
        @close="createmulti = false"
        @MultiTasks="onCreate(); createmulti = false"
        @OneTask="createmulti = false; onCreate()"
        :typeSuccess="'success'"
    >
      <!-- <template v-slot:header>
        {{ $t("123123") }}
      </template> -->
      <template v-slot:description>
        {{ nonEmptyNames.length }}
      </template>
    </CreateMultiTasksDialog
    >
  </v-dialog>
</template>

<script>
import _ from "lodash";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import activityMixin from "./taskDescriptionMixins/activityMixin";
import dialogMixin from "@/mixins/dialogMixin";
import {mapGetters} from "vuex";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import notifications from "@/mixins/notifications";
import stepsDialog from "@/components/boards/dialogs/stepsDialog.vue";
import attachmentsDialog from "@/components/boards/dialogs/attachmentsDialog.vue";
import usersCard from "@/components/boards/dialogs/usersCard.vue";
import labelsListDialog from "@/components/boards/dialogs/labelsListDialog.vue";
import cardsService from "@/services/request/cards/cardsService";
import activeList from "@/mixins/activeList";
import EventBus from "@/events/EventBus";
import tImgMixin from "@/mixins/tImgMixin";
import labelsMixin from "./taskDescriptionMixins/labelsMixin";
import usersMixin from "./taskDescriptionMixins/usersMixin";
import boardsService from "@/services/request/boards/boardsService";
import user from "@/mixins/user";
import attachmentsMixin from "./taskDescriptionMixins/attachmentsMixin";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import TimeTrackerDialog from "@/components/boards/dialogs/timeTrackerDialog.vue";
import checklistDialog from '@/components/boards/dialogs/ChecklistDialog.vue';
import * as getterTypes from "@/store/modules/sideBarValues/types/getters";
import testCasePanel from "@/components/testCases/testCasePanel.vue";
import projectsService from "@/services/request/projects/projectsService";
import CreateMultiTasksDialog from "@/components/dialog/CreateMultiTasksDialog.vue";

export default {
  mixins: [
    user,
    dialogMixin,
    notifications,
    validationMixin,
    activityMixin,
    activeList,
    tImgMixin,
    labelsMixin,
    usersMixin,
    attachmentsMixin,
  ],
  data: () => ({
    timeHours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    timeMinutes: [0, 15, 30, 45],
    loading: false,
    changed: false,
    inputTask: {},
    copyTask: {},
    isNameFieldFocused: false,
    selectedListText: "",
    stepsDialog: false,
    usersCard: false,
    labelsDialog: false,
    visibleConfirm: false,
    priorities: ["P1", "P2", "P3", "P4", "P5"],
    moveMenuVisible: false,
    boardsList: [],
    parentsCards: [],
    chosenBoard: {},
    chosenParentCard: {},
    activeLists: [],
    activeCardList: {},
    //storyPointsList: ["", "1", "2", "3", "5", "8", "13", "21", "34", "55"],
    storyPointsList: [
      { text: "--", value: "" },
      { text: "1", value: "1" },
      { text: "2", value: "2" },
      { text: "3", value: "3" },
      { text: "5", value: "5" },
      { text: "8", value: "8" },
      { text: "13", value: "13" },
      { text: "21", value: "21" },
      { text: "34", value: "34" },
      { text: "55", value: "55" }
    ],

    previousListId: "",
    confirmArchive: false,
    timePickerDialog: false,
    checklistDialogVisible: false,
    enterCount: 0,
    tasksArray: [],
    createmulti: false,
    nonEmptyNames: [],
  }),
  props: {
    task: {
      required: true,
      default: Object,
    },
    type: {
      required: true,
    },
    board: {
      required: true,
      type: Object,
    },
    lists: {
      required: true,
    },
    users: {
      required: true,
    },
    labels: {
      required: true,
    },
  },
  validations: {
    inputTask: {
      name: {required},
      list_id: {required},
    },
  },
  components: {
    testCasePanel,
    TimeTrackerDialog,
    ConfirmLeftDialog,
    stepsDialog,
    attachmentsDialog,
    usersCard,
    labelsListDialog,
    ConfirmDialog,
    checklistDialog,
    CreateMultiTasksDialog,
  },
  async mounted() {
    this.loading = true;
    this.initData();
    await this.initList();
    this.initDependencies();
    this.initPaste();
    await this.getParentCards();
    this.chosenParentCard = this.parentsCards.find(
        (item) => item.id === this.inputTask.parent_id
    );
    this.focusTextarea();
    this.loading = false;
  },
  beforeDestroy() {
    window.removeEventListener("paste", this.onPaste);
  },
  methods: {
    handleInput() {
      let splitLines = this.inputTask.name.split('\n');
      this.nonEmptyNames = splitLines.filter(name => name.trim() !== '');
      this.tasksArray = [...this.nonEmptyNames];
      if(this.tasksArray.length > 1) { this.enterCount++; }
    },

    handleKeyDown(event) {
      if (event.shiftKey && event.key === 'Enter' || event.key === 'Enter') {
        this.enterCount++;
        let splitLines = this.inputTask.name.split('\n');
        this.tasksArray.push(...splitLines);
      }
    },
    CheckCreate() {
      if (this.enterCount >= 1 && this.tasksArray.length > 1) {
        this.createmulti = true;
      } else {
        this.onCreate();
      }
    },
    focusTextarea() {
      this.$nextTick(() => {
        if (this.$refs.inputMsg) {
          this.$refs.inputMsg.focus();
        }
      });
    },
    openChecklistDialog() {
      this.checklistDialogVisible = true;
    },
    onFileAdded() {
      this.changed = true;
    },
    changeTask() {
      if (this.activitiesVisible) {
        this.GetActivityMessage();
      }
    },
    changeTaskImage(file) {
      if (!_.isEqual(this.inputTask.cards_media, file)) {
        this.inputTask.cards_media = file;
        this.pastedImage(file);
      }
    },
    initData() {
      if (this.type === "create") {
        this.task.type = "Task";
        this.task.priority = "P5";
        this.task.status = 0;
        this.task.story_points = 1;
        this.task.assigned_users = [];
        this.task.assigned_labels = [];
        this.task.cards_media = null;
      }
    },
    async initList() {
      this.activeLists = [...this.lists];
      this.activeCardList = this.activeList;
      if (this.activeCardList !== "all") {
        this.task.list_id = this.activeCardList;
      } else if (this.type === "create") {
        this.task.list_id = this.activeLists[0].id;
      }
      const selectedList = this.activeLists.find(
          (item) => item.id === this.task.list_id
      );
      if (selectedList) {
        this.selectedListText = selectedList.name;
      }
      if (this.type === "edit") {
        this.boardsList = await boardsService.getBoardsList(
            this.board.project_id
        );
        this.chosenBoard = this.boardsList.find(
            (item) => item.id === this.board.id
        );
      }
      if(this.task.status === true) {
        this.task.status = 1;
      }
    },
    initDependencies() {
      this.inputTask = {...this.task};
      this.copyTask = {...this.task};
      this.T_IMG_OBJECT = this.inputTask;
      this.T_IMG_COPY_OBJECT = this.copyTask;
      this.T_IMG_MEDIA_KEY = "cards_media";
      if (this.inputTask.cards_media) {
        EventBus.$emit("is-selected-image", this.inputTask.cards_media);
      }
    },
    async getParentCards() {
      try {
        const allParentCards = await projectsService.getParentCards(
            this.projectId
                ? this.projectId
                : this.board?.project_id
                    ? this.board?.project_id
                    : this?.project?.project_id
        );
        this.parentsCards = allParentCards.filter(
            (item) => item.status !== 1 && item.id !== this.inputTask.id
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    async onCreate() {
      if (this.nameErrors && this.listUuidErrors) {
        if (!this.$v.$invalid) {
          this.loading = true;
          try {
            const formData = new FormData();
            if(this.createmulti === true) {
              this.tasksArray.forEach((task, index) => {
                if (task != "") {
                  formData.append(`names[${index}]`, task);
                }
              });
            } else { 
              formData.append("name", this.inputTask.name); 
            }
            this.inputTask.description
              ? formData.append("description", this.inputTask.description)
              : null;
            this.inputTask.cards_media
              ? formData.append("card_media", this.inputTask.cards_media)
              : null;
            this.inputTask.end_date
              ? formData.append("end_date", this.inputTask.end_date)
              : null;
            this.inputTask.is_parent
              ? formData.append("is_parent", this.inputTask.is_parent)
              : null;
            this.inputTask.start_date
              ? formData.append("start_date", this.inputTask.start_date)
              : null;
            this.inputTask.parent_id
              ? formData.append("parent_id", this.inputTask.parent_id)
              : null;
            formData.append("priority", this.inputTask.priority);
            formData.append("type", this.inputTask.type);
            formData.append("status", this.inputTask.status);
            formData.append("story_points", this.inputTask.story_points);
            let createdCard = [];


            if (this.createmulti === true) {
              formData.append("list_id", this.inputTask.list_id);
              createdCard = await cardsService.createCardMulti(
                this.board.project_id,
                formData
              );
              if (this.inputTask.assigned_users.length) {
                createdCard.data.forEach((card) => {
                  card.assigned_users = [
                    ...(card.assigned_users || []), 
                    ...this.inputTask.assigned_users
                  ];
                });
              }
            } else {
              createdCard = await cardsService.createCard(
                this.board.project_id,
                this.board.id,
                this.inputTask.list_id,
                formData
              );
            }
            if (this.inputTask.assigned_labels.length > 0) {
              const params = {
                labels: this.inputTask.assigned_labels.map(user => user.id)
              };

              if (!createdCard.data) {
                await cardsService.addLablesCardCreate(
                  this.board.project_id,
                  this.board.id,
                  this.inputTask.list_id,
                  createdCard.id,
                  params
                );

                createdCard.assigned_labels = [
                  ...(createdCard.assigned_labels || []), 
                  ...this.inputTask.assigned_labels
                ];
              }
              else if (createdCard.data) {
                createdCard.data.forEach(async (card) => {
                  await cardsService.addLablesCardCreate(
                    this.board.project_id,
                    this.board.id,
                    this.inputTask.list_id,
                    card.id,
                    params
                  );
                  card.assigned_labels = [
                    ...(createdCard.assigned_labels || []), 
                    ...this.inputTask.assigned_labels
                  ];
                })
              }
            }
            if (this.inputTask.assigned_users.length && !createdCard.data) {
              const params = {
                emails: this.inputTask.assigned_users.map(user => user.email)
              };
              await cardsService.addUsersToCard(
                this.board.project_id,
                this.board.id,
                this.inputTask.list_id,
                createdCard.id,
                params
              );
              createdCard.assigned_users = [
                ...(createdCard.assigned_users || []), 
                ...this.inputTask.assigned_users
              ];
            }
            else {
              if (this.inputTask.assigned_users.length) {
                createdCard.data.forEach(async (card) => {
                  const params = {
                    emails: this.inputTask.assigned_users.map(user => user.email)
                  };
                  await cardsService.addUsersToCard(
                    this.board.project_id,
                    this.board.id,
                    this.inputTask.list_id,
                    card.id,
                    params
                  );
                  //card.assigned_users = [
                  //  ...(card.assigned_users || []), 
                  //  ...this.inputTask.assigned_users
                  //];
                });
              }
            }
            this.setSuccessNotification(this.$t("profile.successfully_created"));
            EventBus.$emit("taskCreated", createdCard, this.tasksArray.length);
          } catch (e) {
            this.setErrorNotification(e.message);
          } finally {
            this.loading = false;
          }
        }
      } else {
        this.setErrorNotification(this.$t("form.errors.listAndNameRequired"));
      }
    },
    async onUpdate() {
      if (this.nameErrors && this.listUuidErrors) {
        if (!this.$v.$invalid) {
          this.loading = true;
          try {
            const formData = new FormData();
            const changedData = this.$getChangedData(
                this.inputTask,
                this.copyTask
            );
            let hasListId = false;
            changedData.forEach((data) => {
              if (data.field === "cards_media") {
                data.field = "card_media";
                if (data.value == null) {
                  data.value = "";
                }
              }
              if (data.field === "list_id") {
                hasListId = true;
              }
              if (data.field === "is_parent") {
                if (data.field === "is_parent") {
                  data.value = this.isParentBoolean ? 1 : 0;
                  this.inputTask.is_parent = data.value;
                }
              }
              formData.append(data.field, data.value);
            });

            formData.append("_method", 'patch');

            const updatedTask = await projectsService.updateParentCards(
                this.board.project_id,
                this.inputTask.id,
                formData
            );
            this.setSuccessNotification(
                this.$t("profile.successfully_updated")
            );
            this.$emit("UpdateTaskTotal", this.inputTask);
            if (!hasListId) {
              EventBus.$emit("taskModified", updatedTask);
              this.$emit("updateParent", this.inputTask);
            } else {
              EventBus.$emit("taskDeleted", this.task.id);
            }
          } catch (e) {
            this.setErrorNotification(e.message);
          } finally {
            this.loading = false;
            this.onCancel();
          }
        }
      } else {
        this.setErrorNotification(this.$t("form.errors.listAndNameRequired"));
      }
    },
    async onArchive() {
      this.loading = true;
      try {
        await cardsService.deleteCard(
            this.board.project_id,
            this.board.id,
            this.inputTask.list_id,
            this.inputTask.id
        );
        this.setSuccessNotification(this.$t("profile.successfully_deleted"));
        this.$emit("ArchiveTaskTotal", this.inputTask.id);
        EventBus.$emit("taskDeleted", this.inputTask.id);
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.loading = false;
        this.onCancel();
      }
    },
    savePreviousId(item) {
      this.previousListId = item.list_id;
    },
    async onMoveList() {
      this.selectedListText = this.activeLists.find(
          (item) => item.id === this.inputTask.list_id
      ).name;
      this.$refs.moveMenu.save();
    },
    async onChangeBoard(item) {
      this.activeLists = await boardsService.getLists(
          this?.board?.project_id,
          item
      );
      if (this.activeLists?.length) {
        this.activeCardList = this.activeLists[0];
        this.inputTask.list_id = this.activeCardList.id;
        this.selectedListText = this.activeCardList.name;
      }
    },
    async onChangeParentCard(item) {
      this.inputTask.parent_id = item;
    },
    onCancel() {
      this.$emit("close");
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e);
      }
    },
    changeTaskType(item) {
      item.type == "Bug" ? (item.type = "Task") : (item.type = "Bug");
    },
    priorityColor(priority) {
      switch (priority) {
        case "P1": {
          return `#AE2E24`;
        }
        case "P2": {
          return `#D35400`;
        }
        case "P3": {
          return `#F39C12`;
        }
        case "P4": {
          return `#27AE60`;
        }
        case "P5": {
          return `#2ECC71`;
        }
      }
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onCancel();
    },
    setCardLoading(status) {
      this.loading = status;
    },
  },
  computed: {
    isParentBoolean: {
      get() {
        return this.inputTask.is_parent == 1;
      },
      set(newVal) {
        this.inputTask.is_parent = newVal ? 1 : 0;
      },
    },
    ...mapGetters("sideBarValues", {
      copiedUuid: getterTypes.GET_COPIED_UUID,
      copiedUuidBoard: getterTypes.GET_COPIED_UUID_BOARD,
    }),
    ...mapGetters(["currentLanguage"]),
    nameErrors() {
      if (!this.$v.inputTask.name.required) {
        return false;
      }
      return true;
    },
    listUuidErrors() {
      if (!this.$v.inputTask.list_id.required) {
        return false;
      }
      return true;
    },
    attachmentsVisible() {
      return Object.keys(this.inputTask).length > 0 && this.type != "create";
    },
    ...mapGetters(["currentLanguage"]),
  },
  watch: {
    inputTask: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyTask, this.inputTask);
      },
    },
  },
};
</script>

<style lang="scss">
@import "taskDescriptionDialog";
</style>
