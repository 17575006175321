import Vue from "vue";
import VueRouter from "vue-router";
import authService from "@/services/request/auth/authService";
import store from "@/store";
import LoginGuard from "@/components/guard/LoginGuard.vue";
import * as actionType from "@/store/modules/sideBarValues/types/actions";

Vue.use(VueRouter);

const isAuthenticated = async (to, from, next) => {
  if (authService.getToken()) {
    return next();
  } else {
    next({ name: "login" });
  }
};

const isNotAuthenticated = (to, from, next) => {
  if (!authService.getToken()) {
    next();
    return;
  }
  next({ name: "home" });
};

const passwordReset = (to, from, next) => {
  if (from.name == "login") {
    next("/login");
    return;
  }
  next();
};

const routes = [
  {
    path: "/:lang?/login",
    name: "login",
    beforeEnter: isNotAuthenticated,
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/:lang?/register",
    name: "register",
    beforeEnter: isNotAuthenticated,
    component: () => import("@/views/Register.vue"),
  },
  {
    path: "",
    component: LoginGuard,
    beforeEnter: isAuthenticated,
    children: [
      {
        path: "/:lang?/",
        name: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/:lang?/boards",
        name: "projectInfo",
        component: () => import("@/views/projectByUuid.vue"),
        children: [],
      },
      {
        path: "/:lang?/userSetting",
        name: "SettingUser",
        component: () => import("@/views/UserSettings.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const savedRoutes = JSON.parse(localStorage.getItem('tracker-data')) ? JSON.parse(localStorage.getItem('tracker-data')).copiedListRoutes : [];
if (Array.isArray(savedRoutes)) {
  const newRoutes = savedRoutes.map(route => ({
    path: `${route.path}`,
    name: `${route.name}`,
    component: () => import('@/views/Boards.vue'),
    props: true,
  }));
  newRoutes.forEach(route => {
    router.addRoute('projectInfo', route);
  });
} else {
  console.warn('Saved routes are not in an array format:', savedRoutes);
}

router.beforeEach((to, from, next) => {
  console.log(to)
  if (!to.matched.length) {
    return next({ name: "home" });
  } else {
    if (authService.getToken()) {
      const permissionsPage = JSON.parse(localStorage.getItem('pages'));
      store.dispatch(`sideBarValues/${actionType.SET_COPIED_UUID_BOARD}`,  to.path.split("/").pop());
      next();
      if (permissionsPage[to?.name]) {
        next();
      } else {
        console.log("WORK NOT MATCHED LENGTH ELSE PERMISSION PAGE ELSE")
        const pages = Object.keys(permissionsPage);
        const allowedPages = pages.filter((page) => permissionsPage[page]);
        next({ name: allowedPages[0] });
      }
    } else {
      next();
    }
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "Task Tracker";
  });
});

export default router;
