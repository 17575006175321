<template>
  <v-row>
    <v-col cols="auto" class="pa-2">
      <v-col class="pb-2 pl-0 task-desc-title">
        {{ $t("form.taskDescriptionDialog.TimeTextTask") }}
      </v-col>
      <v-col class="pt-0 mt-3">
        <v-row style="gap: 5px">
          <v-select
              :items="timeHours"
              hide-details
              outlined
              dense
              v-model="inputTask.inputTimeHours"
              item-color="orange"
              color="orange"
              style="max-width: 75px; width: 75px"
          ></v-select>
          <v-select
              :items="timeMinutes"
              hide-details
              outlined
              dense
              v-model="inputTask.inputTimeMinutes"
              item-color="orange"
              color="orange"
              style="max-width: 75px; width: 75px"
          ></v-select>
        </v-row>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    timeHours: {
      type: Array,
      required: true
    },
    timeMinutes: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    inputTask: {
      inputTimeHours: null,
      inputTimeMinutes: null,
    },
  }),
};
</script>

