import * as mutationTypes from './types/mutations';
import { DEFAULT_STORAGE } from "@/mixins/constants";

export default {
  [mutationTypes.SET_ACTIVE_LIST]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("tracker-data") !== null) {
      data = JSON.parse(localStorage.getItem("tracker-data"));
    }
    data.activeList = value;
    localStorage.setItem("tracker-data", JSON.stringify(data));

    state.activeList = value;
  },
  [mutationTypes.SET_ACTIVE_LIST_TAB]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("tracker-data") !== null) {
      data = JSON.parse(localStorage.getItem("tracker-data"));
    }
    data.activeListTabs = value;
    localStorage.setItem("tracker-data", JSON.stringify(data));

    state.activeListTabs = value;
  },
};