<template>
  <AppLayout>
    <RouterView />
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/layout/AppLayout.vue";

export default {
  name: 'LoginGuard',
  components: {
    AppLayout
  }
};
</script>

<style scoped>

</style>
