<template>
  <v-dialog
    v-model="visibility"
    max-width="500"
    content-class="cross__dialog"
    :persistent="changed"
    :transition="$dialogTransition"
    @click:outside="showConfirmDialog"
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
          v-if="loading"
          absolute
          top
          indeterminate
          color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title class="justify-center">
        {{ type == "create" ? $t('form.testCases.createTestCase') : $t('form.testCases.editTestCase') }}
      </v-card-title>
      <v-card-text class="pb-0 px-4">
        <v-row no-gutters>
          <v-col cols="12"
            ><v-text-field
              :label="$t('table.title')"
              v-model="inputCase.name"
              hide-details
              :maxlength="100"
              color="orange"
              outlined
              dense
          /></v-col>
          <v-col cols="12" class="py-1 px-0" v-if="caseIn != {} && type === 'create'">
            {{ $t('form.testCases.createdin') }} <v-icon>{{ caseIn.icon }}</v-icon> {{ caseIn.name }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <v-btn
              dark
              block
              class="br-5 text-transform-none"
              @click="showConfirmDialog"
              >{{ $t("btn.cancel") }}</v-btn
            >
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn
              v-if="type === 'edit'"
              block
              class="btn-orange-bg text-transform-none br-5"
              :disabled="!changed"
              :loading="loading"
              @click="onUpdate()"
              >{{ $t("btn.update") }}</v-btn
            >
            <v-btn
              v-if="type === 'create'"
              block
              class="btn-orange-bg text-transform-none br-5"
              :disabled="!changed"
              :loading="loading"
              @click="onCreate()"
              >{{ $t("btn.create") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onCancel"
    />
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import testCasesService from "@/services/request/testCases/testCasesService";
import notifications from "@/mixins/notifications";
import testSuitsCasesMixin from "@/mixins/testSuitsCasesMixin";
import EventBus from "@/events/EventBus";

export default {
  mixins: [dialogMixin, validationMixin, notifications, testSuitsCasesMixin],
  data: () => ({
    loading: false,
    changed: false,
    visibleConfirm: false,
    inputCase: {},
    copyCase: {},
  }),
  validations: {
    inputCase: {
      name: { required },
    },
  },
  props: {
    type: {
      required: true,
    },
    caseIn: {
      required: false,
      default: {},
    },
    projectId: {
      required: true,
    },
  },
  components: {
    ConfirmLeftDialog,
  },
  async mounted() {
    this.onMounted();
  },
  methods: {
    async onMounted() {
      if(this.type === 'edit') {
        this.inputCase = { ...this.caseIn };
        this.copyCase = { ...this.inputCase };
      }
    },
    onCancel() {
      this.$v.$reset();
      this.$emit("close");
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onCancel();
    },
    async onCreate() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("name", this.inputCase.name);
        await testCasesService.createTestCase(
          this.projectId,
          this.caseIn.id,
          formData
        );

        await this.fetchChildren(this.caseIn);

        this.setSuccessNotification(this.$t("profile.successfully_created"));
        this.visibility = false;
      } catch (e) {
        console.log(e.message);
      } finally {
        this.loading = false;
      }
    },
    async onUpdate() {
      try {
        const formData = new FormData();
        const changedData = this.$getChangedData(this.inputCase, this.copyCase);
        changedData.forEach((data) => {
          formData.append(data.field, data.value);
        });
        // example for PATCH method
        formData.append("_method", 'patch');
        if (this.inputCase.card_id) {
          formData.append("card_id", this.inputCase.card_id);
        }
        await testCasesService.updateTestCase(this.projectId, this.inputCase.test_suit_id, this.inputCase.id, formData);

        this.copyCase = {...this.inputCase};
        this.inputCase = {...this.copyCase};

        EventBus.$emit('updateTestCase', this.inputCase);

        await this.onUpdateCase(this.caseIn);

        this.visibility = false;
        this.setSuccessNotification(this.$t("profile.successfully_updated"));
      } catch (e) {
        console.log(e)
        this.setErrorNotification(this.$t("form.userisundefined"));
      }
    }
  },
  watch: {
    inputCase: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyCase, this.inputCase);
      },
    },
  },
};
</script>
