import * as actionTypes from "./types/actions";
import * as mutationTypes from "./types/mutations";

export default {
  [actionTypes.SET_COPIED_DATA]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_COPIED_DATA, value);
  },
  [actionTypes.SET_COPIED_ROUTES]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_COPIED_ROUTES, value);
  },
  [actionTypes.SET_COPIED_UUID]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_COPIED_UUID, value);
  },
  [actionTypes.SET_COPIED_PROJECT]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_COPIED_PROJECT, value);
  },
  [actionTypes.SET_COPIED_UUID_BOARD]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_COPIED_UUID_BOARD, value);
  },
};
