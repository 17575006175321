<template>
  <v-snackbar
    :value="true"
    absolute
    left
    bottom
    :color="notification.type"
    :style="{ 'margin-bottom': index * 60 + 10 + 'px' }"
  >
    {{ notification.text }}
  </v-snackbar>
</template>

<script>
import { mapActions } from "vuex";
import * as actionTypes from "@/store/modules/notifications/types/actions";

export default {
  name: "Notification",
  props: {
    notification: {
      required: true,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    defaultTimeOut: 3000,
    changeOpacityPeriod: 50,
    opacityDeltaInPercents: 10,
  }),
  methods: {
    ...mapActions("notifications", {
      closeNotification: actionTypes.CLOSE_NOTIFICATION,
    }),
  },
  computed: {
    // notificationColor() {
    //   return this.notification.type === "success"
    //     ? "#FF7A00"
    //     : this.notification.type;
    // },
  },
  mounted() {
    let self = this;
    const opacityToZeroTime =
      this.changeOpacityPeriod * (100 / this.opacityDeltaInPercents);
    setTimeout(() => {
      setInterval(() => {
        const element = self.$refs[`notification_${self.notification.id}`];
        if (element?.style) {
          element.style.opacity -= self.opacityDeltaInPercents / 100;
        }
      }, this.changeOpacityPeriod);
    }, this.defaultTimeOut - opacityToZeroTime);
    self = this;
    setTimeout(() => {
      self.closeNotification(self.notification.id);
    }, this.defaultTimeOut);
  },
};
</script>

<style scoped lang="scss">
.notification {
  position: relative;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px;
  vertical-align: middle;
  user-select: none;
  color: white;
  z-index: 999;
  pointer-events: auto !important;
  &-error {
    background: var(--v-error-base);
  }
  &-success {
    background: #0a4e0c;
  }
  &-info {
    background: blue;
  }
  &-warning {
    background: rgb(255, 162, 0);
  }
}
@media screen and (max-width: 960px) {
  .notification {
    width: 200px;
    font-size: 12px;
  }
}
</style>
