<template>
  <div>
    <div class="treeview-container">
    <v-treeview
        dense
        hoverable
        activatable
        selected-color="orange"
        color="orange"
        selection-type="independent"
        class="testCases-tree"
        :class="{ 'TestCases-tree-Visible': IsMobile }"
        :items="mainList"
        :no-data-text="$t('table.noData')"
        return-object
        :open.sync="open"
        :active.sync="active"
        :load-children="fetchChildren"
    >
      <template v-slot:prepend="{ item }">
        <div class="d-flex">
          <p class="ma-0 pa-0 grey--text mr-5">{{ item?.number }}</p>
          <v-icon dense>
            {{ `${item?.icon}` }}
          </v-icon>
        </div>
      </template>
      <template v-slot:label="{ item }">
        <div @click="cheeeeeck(item)" class="d-flex justify-content-space-between align-center pr-3">
          <span>{{ truncateName(item?.name) }}</span>
          <v-checkbox v-if="item.type === 'case' && type == 'fromTask'" v-model="item.is_assigned" hide-details @click.stop="checkTCase(item)" color="orange"/>
          <v-slide-x-reverse-transition>
            <v-menu  v-if="type == 'fromPage'" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-show="$vuetify.breakpoint.width <= 1150 ? true : activedLast == item.id"
                    class="cursor-pointer"
                    icon
                    v-on="on"
                    v-bind="attrs"
                    small
                >
                  <v-icon color="grey lighten-1" dense>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list class="pa-1" dense>
                <v-list-item
                    v-if="item.type === 'suit'"
                    @click="onAddTestSuit(item)"
                    class="d-flex align-center"
                >
                <span class="mr-2">{{ $t('form.testCases.addTestSuite') }}</span>
                  <v-icon size="20">mdi-folder-plus</v-icon>
                </v-list-item>
                <v-list-item
                    v-if="item.type === 'suit'"
                    @click="onAddTestCase(item)"
                    class="d-flex align-center"
                >
                <span class="mr-2">{{ $t('form.testCases.addTestCase') }}</span>
                  <v-icon size="20">mdi-clipboard-plus</v-icon>
                </v-list-item>
                <v-list-item
                    v-if="item.type === 'suit'"
                    @click="onEditCaseSuit(item)"
                    class="d-flex align-center"
                >
                  <span class="mr-2">{{ $t('form.testCases.edit') }}</span>
                  <v-icon size="20">mdi-pencil</v-icon>
                </v-list-item>
                <v-list-item
                    @click="
                    onDelete(item)
                  "
                    class="d-flex align-center"
                >
                  <span class="mr-1 text-error">{{ $t("btn.delete") }} </span>
                  <v-icon color="error" size="20">mdi-trash-can</v-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-slide-x-reverse-transition>
        </div>
      </template>
    </v-treeview>
    <ConfirmDialog
        v-if="deleteTestSuitVisible"
        :visible="deleteTestSuitVisible"
        @close="deleteTestSuitVisible = false"
        @onConfirm="onDeleteTestSuit()"
        @onCancel="deleteTestSuitVisible = false"
        :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("form.deleteCardDialog.delete_dialog_title") }}
      </template>
      <template v-slot:description>
        {{ $t("form.deleteCardDialog.delete_dialog_desc") }}
      </template></ConfirmDialog
    >
    <ConfirmDialog
        v-if="deleteTestCaseVisible"
        :visible="deleteTestCaseVisible"
        @close="deleteTestCaseVisible = false"
        @onConfirm="onDeleteTestCase()"
        @onCancel="deleteTestCaseVisible = false"
        :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("form.deleteCardDialog.delete_dialog_title") }}
      </template>
      <template v-slot:description>
        {{ $t("form.deleteCardDialog.delete_dialog_desc") }}
      </template></ConfirmDialog
    >
    <testSuitDialog
        v-if="testSuitDialog.visible"
        :visible="testSuitDialog.visible"
        :suit="testSuitDialog.item"
        :type="testSuitDialog.type"
        :projectId="projectId"
        @close="closeDialog('testSuit')"
    />
    <testCaseDialog
        v-if="testCaseDialog.visible"
        :visible="testCaseDialog.visible"
        :caseIn="testCaseDialog.item"
        :type="testCaseDialog.type"
        :projectId="projectId"
        @close="closeDialog('testCase')"
    />
    </div>
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import EventBus from "@/events/EventBus";
import testCaseDialog from "@/components/testCases/testCaseDialog.vue";
import testSuitDialog from "@/components/testCases/testSuitDialog.vue";
import _ from "lodash";
import testSuitsCasesMixin from "@/mixins/testSuitsCasesMixin";
import cardsService from "@/services/request/cards/cardsService";
import {mapGetters} from "vuex";
import * as getterTypes from "@/store/modules/sideBarValues/types/getters";

export default {
  mixins: [notifications, testSuitsCasesMixin],
  data: () => ({
    mainList: [],
    item: {},
    active: [],
    activedLast: '',
    items: [
      {
        children: []
      }
    ],
    deleteTestSuitVisible: false,
    deleteTestCaseVisible: false,
    testSuitDialog: {
      visible: false,
      item: {},
      type: "",
    },
    testCaseDialog: {
      visible: false,
      item: {},
      type: "",
    },
    open: [],
    IsMobile: false,
  }),
  props: {
    projectId: {
      required: true,
    },
    opened: {
      required: true,
    },
    gobacksuits: {
      required: false,
    },
    type: {
      required: true
    },
    task: {
      required: false
    }
  },
  components: {
    testSuitDialog, testCaseDialog,
    ConfirmDialog,
  },
  async mounted() {
    EventBus.$on('updateTreeAfterAdding', (item) => {
      item.type = 'suit';
      item.children = [];
      this.mainList.unshift(item);
    });

    await this.fetchSuits([], true);
    this.mainList = _.cloneDeep(this.items);
    if (!this.opened.length) {
      this.open = this.mainList.map(item => item.id);
    }
  },
  methods: {
    cheeeeeck(item) {
      console.log(item)
    },
    checkTCase(item) {
      if(item.is_assigned) {
        this.addTestCaseToCard(item);
      } else {
        EventBus.$emit('removeTestCaseFromTask', item);
      }
    },
    async addTestCaseToCard(testCase) {
      try {
        const formData = new FormData();
        formData.append("test_case_id", testCase.id);
        const newTestCase = await cardsService.addTestCaseToCard(
            this.copiedUuid,
            this.copiedUuidBoard,
            this.task.list_id,
            this.task.id,
            formData
        );
        this.setSuccessNotification(this.$t("profile.successfully_added"));
        this.onUpdateCaseIsChoosen(testCase);
        EventBus.$emit('chooseNewTestCase', newTestCase);
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    closeDialog(type) {
      if(type == "testCase") {
        this.testCaseDialog.visible = false;
      } else {
        this.testSuitDialog.visible = false;
      }
    },
    truncateName(name) {
      if (name?.length > 30) {
        return name?.slice(0, 30) + '...';
      }
      return name;
    },
  },
  computed: {
    ...mapGetters("sideBarValues", {
      copiedUuid: getterTypes.GET_COPIED_UUID,
      copiedUuidBoard: getterTypes.GET_COPIED_UUID_BOARD,
    }),
    ...mapGetters(["currentLanguage"]),
  },
  watch: {
    active: {
      handler(value) {
        if (value[0]?.type == 'case') {
          this.$emit("openTestCase", value[0]);
          if (this.$vuetify.breakpoint.width <= 1150) {
            this.IsMobile = !this.IsMobile;
            this.$emit("IsMobileBtn", this.IsMobile);
          }
        } else {
          this.$emit("openTestCase", {});
        }
        if (value[0]) {
          this.activedLast = value[0].id;
        } else {
          this.activedLast = 1;
        }

      }
    },
    gobacksuits: {
      handler(value) {
        if(value === false) {
          this.IsMobile = false;
        }
      }
    }
  },
};
</script>

<style lang="scss">
.testCases-tree {
  max-width: 20%;
  min-width: 100dvw;
  font-size: 15px;
  width: 100%;
  .theme--light & {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
  .theme--dark & {
    border-right: thin solid rgba(255, 255, 255, 0.12);
  }
}
.TestCases-tree-Visible {
  width: 0px;
  min-width: 0px;
}
@media (min-width: 1151px) {
  .testCases-tree { 
    min-width: 480px;
  }
}
.treeview-container {
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
