<template>
  <v-menu
    bottom
    transition="fade-transition"
    offset-y
    :close-on-content-click="false"
    ref="tAvatarMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" style="display: inline-block">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltipOn }">
            <div v-on="tooltipOn" style="display: inline-block">
              <v-avatar
                class="t-avatar"
                :style="{
                  minWidth: `${size}px`,
                  width: `${size}px`,
                  height: `${size}px`,
                  margin: isPadding ? '' : '0px -1px',
                }"
                :class="user?.profile_image ? '' : 't-avatar-letters'"
              >
                <v-img
                  v-if="user?.profile_image"
                  :src="user?.profile_image"
                  :style="`width: ${size}px; height: ${size}px`"
                  cover
                ></v-img>
                <span v-else>{{ getLetters(user) }}</span>
              </v-avatar>
            </div>
          </template>
          <span>{{ user?.name || "Невідомий користувач" }}</span>
        </v-tooltip>
      </div>
    </template>
    <v-card width="320" :height="canDelete ? '150' : '110'">
      <v-card-title class="t-header-bg">
        <v-btn
          icon
          small
          elevation="0"
          absolute
          style="right: 5px; top: 6px; z-index: 101"
        >
          <v-icon size="22" color="white" @click="closeMenu()"
            >mdi-close</v-icon
          >
        </v-btn>
      </v-card-title>
      <v-card-text
        class="pa-1 d-flex align-center"
        style="position: relative; top: -37px; left: 10px"
      >
        <v-avatar
          class="t-avatar-menu"
          :class="user?.profile_image ? '' : 't-avatar-letters--open'"
        >
          <v-img
            v-if="user?.profile_image"
            :src="user?.profile_image"
            style="width: 88px; height: 88px"
            cover
            :style="
              $vuetify.theme.dark ? 'background: #1E1E1E' : 'background: #fff'
            "
          ></v-img>
          <span v-else>{{ getLetters(user) }}</span>
          <!-- <v-icon
            v-else
            :size="86"
            style="height: 90px !important"
            :style="
              $vuetify.theme.dark ? 'background: #1E1E1E' : 'background: #fff'
            "
            >mdi-account-circle</v-icon
          > -->
        </v-avatar>
        <span class="t-avatar-menu-text"
          >{{ user?.username || "--" }}<br /><span
            @click="copyToClipboard(user?.email)"
            class="t-avatar-menu-email copyTxt"
            >{{ user?.email || "--" }}</span
          ></span
        >
      </v-card-text>
      <v-card-actions
        style="position: relative; top: -40px"
        v-if="canDelete == true"
      >
        <v-btn
          block
          class="v-custom-tonal-btn--error"
          elevation="0"
          @click="deleteFromCard()"
          ><v-icon>mdi-trash-can-outline</v-icon
          >{{ $t("boards.delete_from_card") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import notifications from "@/mixins/notifications";
export default {
  mixins: [notifications],
  name: "t-avatar",
  props: {
    user: {
      required: false,
    },
    canDelete: {
      required: false,
      default: false,
    },
    isPadding: {
      required: false,
      default: true,
    },
    size: {
      required: false,
      default: 28,
    },
  },
  methods: {
    deleteFromCard() {
      this.$emit("deleteFromCard", this.user);
    },
    closeMenu() {
      this.$refs.tAvatarMenu.save();
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e);
      }
    },
    getLetters(item) {
      let letters = "";
      if (item?.name && item.surname) {
        letters += item?.name?.trim().charAt(0).toUpperCase();
        letters += item?.surname?.trim().charAt(0).toUpperCase();
      } else if (item?.name) {
        letters += item?.name?.trim().charAt(0).toUpperCase();
      }
      return letters;
    },
  },
};
</script>

<style scoped lang="scss">
.t-header-bg {
  background: #b85900;
  height: 40px;
}
.t-avatar {
  margin: 0 1px;
  &-menu {
    min-width: 88px !important;
    width: 88px !important;
    height: 88px !important;
    .theme--dark & {
      border: thin solid rgba(255, 255, 255, 0.12);
    }
    .theme--light & {
      border: thin solid #ccc;
    }
    &-text {
      font-size: 16px;
      font-weight: 500;
      width: 200px;
      margin-top: 30px;
      margin-left: 8px;
      .theme--dark & {
        color: #fff;
      }
      .theme--light & {
        color: #1e1e1e;
      }
    }
    &-email {
      font-size: 12px;
      line-height: normal;
      text-decoration: underline;
      opacity: 0.8;
    }
  }
  &:hover {
    opacity: 0.7;
    transition: opacity 0.4s;
  }
}
.t-avatar-letters {
  background: linear-gradient(93.49deg, #8b4401 -60.48%, #ff7a00 109.27%);
  font-weight: 500;
  font-size: 13px;
  color: white;
  &--open {
    font-size: 44px;
    .theme--dark & {
      color: #fff;
      background-color: #1e1e1e;
    }
    .theme--light & {
      color: #1e1e1e;
      background-color: #fff;
    }
  }
}
</style>
