<template>
  <v-dialog
      v-model="visibility"
      max-width="800"
      content-class="cross__dialog"
      :transition="$dialogTransition"
      scrollable
      @click:outside="onCancel()"
      persistent
  >
  <v-card class="testCase-panel" elevation="0">
    <v-progress-linear
        v-if="loading"
        indeterminate
        color="orange"
        top
        absolute
    />
    <v-slide-x-transition>
      <div class="cross__inner">
        <v-btn fab small elevation="0" @click="onCancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-slide-x-transition>
    <v-card-title v-if="Object.keys(inputTestCase).length > 0" class="justify-space-between py-1">
      <span style="line-height: 24px; font-size: 18px">
        <v-row no-gutters>
          <v-col class="pt-2 pb-2" cols="auto">
            <v-text-field
                :label="$t('table.title')"
                v-model="inputTestCase.test_case.name"
                hide-details
                :maxlength="128"
                color="orange"
                outlined
                dense
                disabled
            />
          </v-col>
          <v-col class="pt-2 pb-2 pl-2" cols="auto">
            <v-autocomplete
                :items="statusOptions"
                item-text="text"
                item-value="value"
                v-model="inputTestCase.state"
                :label="$t('form.testCases.SelectStatus')"
                dense
                hide-details
                outlined
            />
          </v-col>
        </v-row>
      </span>
      <span class="testCase-date">
        {{
          $t("testCases.created_on") + ' ' +
          formattedDate(inputTestCase?.created_details?.timestamp) + ' ' +
          $t("testCases.created_by")
        }}
        <t-avatar size="18" :user="inputTestCase?.created_details?.user"/><br/>
        {{
          $t("testCases.last_modified_on") + ' ' +
          formattedDate(inputTestCase?.updated_details?.timestamp) + ' ' +
          $t("testCases.created_by")
        }}
        <t-avatar size="18" :user="inputTestCase?.updated_details?.user"/>
      </span>
    </v-card-title>
    <v-divider v-if="testCase && Object.keys(inputTestCase).length > 0" class="mx-4"></v-divider>
    <v-card-text v-if="testCase && Object.keys(inputTestCase).length > 0" class="py-2">
      <v-row v-if="testCase && inputTestCase?.assigned_labels?.length > 0" class="d-flex align-center py-3 pl-4">
        <v-col cols="auto" class="pa-2">
          <v-col class="pb-0 task-desc-title">
            {{ $t("form.taskDescriptionDialog.tags") }}
          </v-col>
          <v-col cols="auto">
            <t-tag
                v-for="label in inputTestCase.assigned_labels"
                :key="label.id"
                :tag="{ name: label.name, bg: label.color }"
            />
          </v-col>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="9">
          <v-textarea
              outlined
              color="orange"
              dense
              :maxLength="750"
              v-model="inputTestCase.test_case.steps"
              rows="10"
              :label="$t('testCases.steps')"
              hide-details
              disabled
          />
        </v-col>
        <v-col cols="3">
          <v-textarea
              class="ml-2"
              outlined
              color="orange"
              dense
              :maxLength="750"
              v-model="inputTestCase.test_case.results"
              rows="10"
              :label="$t('testCases.expected_result')"
              hide-details
              disabled
          />
        </v-col>
      </v-row>
      <v-row class="pb-3">
        <v-col cols="auto" class="pl-1" style="flex-grow: 1">
          <v-text-field
              v-model="inputTestCase.description"
              :placeholder="$t('form.taskDescriptionDialog.PostComment')"
              dense
              class="activity-message-field"
              outlined
              style="font-size: 14px"
              color="orange"
              :maxLength="225"
          />
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col cols="12">
          <v-btn
              class="btn-orange-bg"
              style="min-width: 120px"
              :disabled="!changed"
              @click="onSave"
          >
            {{ $t("btn.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <ConfirmDialog
        v-if="confirmDelete"
        :visible="confirmDelete"
        @close="confirmDelete = false"
        @onConfirm="onDeleteTestCase(testCase)"
        @onCancel="confirmDelete = false"
        :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("form.deleteCardDialog.delete_dialog_title") }}
      </template>
      <template v-slot:description>
        {{ $t("form.deleteCardDialog.delete_dialog_desc") }}
      </template>
    </ConfirmDialog>
  </v-card>
  </v-dialog>
</template>

<script>
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import notifications from "@/mixins/notifications";
import testSuitsCasesMixin from "@/mixins/testSuitsCasesMixin";
import labelsListDialog from "@/components/boards/dialogs/labelsListDialog.vue";
import dialogMixin from "@/mixins/dialogMixin";
import cardsService from "@/services/request/cards/cardsService";

export default {
  data: () => ({
    changed: false,
    confirmDelete: false,
    inputTestCase: {},
    copyTestCase: {},
    labelsList: false,
    loading: false,
    statusOptions: [
      { text: 'New', value: 'new' },
      { text: 'In Progress', value: 'in progress' },
      { text: 'Passed', value: 'passed' },
      { text: 'Failed', value: 'failed' },
      { text: 'Blocked', value: 'blocked' }
    ],
  }),
  components: {
    labelsListDialog,
    ConfirmDialog,
  },
  mixins: [notifications, testSuitsCasesMixin, dialogMixin],
  props: {
    testCase: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    projectId: {
      required: true,
    },
    boardId: {
      required: true,
    },
    task: {
      required: true,
    },
  },
  mounted() {
    this.inputTestCase = {...this?.testCase};
    this.copyTestCase = {...this?.testCase};
  },
  methods: {
    async onSave() {
      try {
        const formData = new FormData();
        const changedData = this.$getChangedData(this.inputTestCase, this.copyTestCase);
        changedData.forEach((data) => {
          formData.append(data.field, data.value);
        });
        formData.append("_method", 'patch');
        const editedTestCase = await cardsService.updateTestCaseInCard(
            this.projectId,
            this.boardId,
            this.task.list_id,
            this.task.id,
            this.inputTestCase.id,
            formData
        );
        this.inputTestCase = editedTestCase;
        this.copyTestCase = {...this.inputTestCase};
        this.inputTestCase = {...this.copyTestCase};
        this.$emit('updateCardTestCase', this.inputTestCase)
        this.setSuccessNotification(this.$t("profile.successfully_updated"));
      } catch (e) {
        console.log(e)
        this.setErrorNotification(this.$t("form.userisundefined"));
      }
    },
    formattedDate(dateTime) {
      const date = new Date(dateTime);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}.${month}.${year}, ${hours}:${minutes}`;
    },
    onCancel() {
      this.$emit("close");
    },
  },
  watch: {
    inputTestCase: {
    deep: true,
    handler() {
      this.changed = !_.isEqual(this.copyTestCase, this.inputTestCase);
    },
  },
    testCase: {
      handler(e) {
        if (e) {
          this.inputTestCase = {...this.nullToEmptyString(e)};
          this.copyTestCase = {...this.nullToEmptyString(e)};
        }
      }
    }
  },
};
</script>

<style lang="scss">
.testCase-panel {
  min-width: 70%;
  width: 100%;
}

.testCase-date {
  font-size: 14px;
  line-height: 20px;

  .theme--light & {
    color: #6b6a6a;
  }

  .theme--dark & {
    color: #ccc;
  }
}
.activity-message-field .v-label {
  line-height: 28px;
  font-size: 14px;
}

.activity-message-field .v-text-field__slot input {
  padding-bottom: 0px;
}

.activity-message-field.v-text-field--outlined fieldset {
  top: 3px;
}
</style>
