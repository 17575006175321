import boardsService from "@/services/request/boards/boardsService";
import notifications from "@/mixins/notifications";

export default {
  mixins: [notifications],
  methods: {
    async addLabelToCard(label, IsTaskBoard) {
      try {
        const formData = new FormData();
        formData.append("label_id", label.id);

        if(IsTaskBoard) {
          await boardsService.addLabelToCard(
            this.board.project_id,
            this.board.id,
            IsTaskBoard.list_id,
            IsTaskBoard.id,
            formData
          );
          IsTaskBoard.assigned_labels.push(label);
        }
        else {
          await boardsService.addLabelToCard(
            this.board.project_id,
            this.board.id,
            this.task.list_id,
            this.task.id,
            formData
          );
          this.task.assigned_labels.push(label);
          this.changeTask();
        }
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    async deleteLabelFromCard(label, IsTaskBoard) {
      try {
        const formData = new FormData();
        formData.append("label_id", label.id);
        if(IsTaskBoard) {
          await boardsService.deleteLabelFromCard(
            this.board.project_id,
            this.board.id,
            IsTaskBoard.list_id,
            IsTaskBoard.id,
            formData
          );
          const searchItem = IsTaskBoard.assigned_labels.findIndex(
            (item) => item.id === label.id
          );
          IsTaskBoard.assigned_labels.splice(searchItem, 1);
        }
        else {
          await boardsService.deleteLabelFromCard(
            this.board.project_id,
            this.board.id,
            this.task.list_id,
            this.task.id,
            formData
          );
          const searchItem = this.inputTask.assigned_labels.findIndex(
            (item) => item.id === label.id
          );
          this.inputTask.assigned_labels.splice(searchItem, 1);
          this.changeTask();
        }
        

      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    labelsClose(type, labels = []) {
      if (type === "create") {
        this.inputTask.assigned_labels = labels;
      }
      this.labelsDialog = false;
    },
  },
};
