import testCasesService from "@/services/request/testCases/testCasesService";
import EventBus from "@/events/EventBus";

export default {
  methods: {
    onEditCaseSuit(item) {
      if(item.type == "suit") {
        this.testSuitDialog.type = "edit";
        this.testSuitDialog.item = item;
        this.testSuitDialog.visible = true;
      } else {
        this.testCaseDialog.type = "edit";
        this.testCaseDialog.item = item;
        this.testCaseDialog.visible = true;
      }
    },
    async onDeleteTestSuit() {
      try {
        await testCasesService.deleteTestSuit(this.projectId, this.item.id);
        this.deleteTestSuitVisible = false;
        await this.updateTreeAfterDeletion(this.item, 'suit');
        EventBus.$emit('afterDeleteSuitCase', {});
        this.setSuccessNotification(this.$t("profile.successfully_deleted"));
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    async onDeleteTestCase() {
      try {
        await testCasesService.deleteTestCase(this.projectId, this.item.test_suit_id, this.item.id);
        this.deleteTestCaseVisible = false;
        await this.updateTreeAfterDeletion(this.item, 'case');
        EventBus.$emit('afterDeleteSuitCase', {});
        this.setSuccessNotification(this.$t("profile.successfully_deleted"));
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    async onDelete(item) {
      this.item = item;
      if (item.type == 'case') {
        this.deleteTestCaseVisible = true;
      } else {
        this.deleteTestSuitVisible = true;
      }
    },
    async updateTreeAfterDeletion(item) {
      const parent = this.findParentItem(this.mainList, item);
      if (parent) {
        parent.children = parent.children.filter(child => child.id !== item.id);
      } else {
        this.mainList = this.mainList.filter(node => node.id !== item.id);
      }
    },
    findParentItem(nodes, item) {
      for (const node of nodes) {
        if (node.children && node.children.some(child => child.id === item.id)) {
          return node;
        }

        if (node.children) {
          const parent = this.findParentItem(node.children, item);
          if (parent) {
            return parent;
          }
        }
      }
      return null;
    },
    async fetchSuits(item, start, update) {
      this.$emit("setLoading", true);
      try {
        const params = {};
        if (item && !update) {
          params.parent_id = item.id;
        } else if (item && update) {
          params.parent_id = item.parent_id;
        }
        const suits = await testCasesService.getTestSuits(this.projectId, params);
        const suitsData = suits.map(suit => this.getData(suit, "suit"));
        if (start) {
          this.items = suitsData;
        }
        return suitsData;
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.$emit("setLoading", false);
      }
    },
    async fetchCurrentSuit(item) {
      this.$emit("setLoading", true);
      try {
        const params = {};
        const suit = testCasesService.getSingleTestSuit(this.projectId, item.parent_id, params).then(
            (res) => {
              const suit = res;
              return suit;
            }
        );
        return suit;
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.$emit("setLoading", false);
      }
    },
    async fetchCases(testSuit, update) {
      this.$emit("setLoading", true);
      try {
        const params = {};
        let id = '';
        if( (testSuit?.test_suit_id && !update) || (update && testSuit?.type == "case")) {
          id = testSuit.test_suit_id
        } else if (update && testSuit?.type == "suit") {
          id = testSuit?.parent_id
        } else {
          id = testSuit?.data?.parent_id
        }

        if(this.task) {
          params.card_id = this?.task?.id;
        }

        const cases = await testCasesService.getTestCase(
            this.projectId,
            id ? id : testSuit.id,
            params
        ).then((res) => {
          const cases = [];
          res.forEach((case1) => {
            cases.push(this.getData(case1, "case"));
          });
          return cases;
        });
        return cases;
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.$emit("setLoading", false);
      }
    },
    async fetchChildren(item, del) {
      if (item.id === 0) {
        item.children = _.cloneDeep(this.items);
      } else {
        if(!del) {
        let children = [];
        children = await this.fetchSuits(item, false);
        let children2 = [];

        children2 = await this.fetchCases(item);

          children2.forEach((child) => {
            if (child.card_assigned !== null) {
              child.is_assigned = true;
            } else {
              child.is_assigned = false;
            }
          });

        const items = children.concat(children2);

          items.forEach((newItem, index) => {
            const existingItemIndex = item.children.findIndex(existingItem => existingItem.id === newItem.id);
            if (existingItemIndex === -1) {
              item.children.splice(index, 0, newItem);
            }
          });
        } else if(del) {
          const suit = await this.fetchCurrentSuit(item);
          item = suit[0];
          item.children = [];
          let children = [];
          children = await this.fetchSuits(item, false);
          let children2 = [];

          children2 = await this.fetchCases(item);

          children2.forEach((child) => {
            if (child.card_assigned !== null) {
              child.is_assigned = true;
            } else {
              child.is_assigned = false;
            }
          });

          const items = children.concat(children2);

          item.children = item.children.filter(existingItem => {
            return items.some(newItem => newItem.id === existingItem.id);
          });
        }

      }
    },
    async onUpdateSuit(item) {
      let children = [];
      children = await this.fetchSuits(item, false, true);
      let children2 = [];

      children2 = await this.fetchCases(item, true);

      const items = children.concat(children2);

      const specificItem = items.find(existingItem => existingItem.id === item.id);
      if (specificItem) {
        item.name = specificItem.name;
      }
    },
    async onUpdateCase(item) {
      const items = await this.fetchCases(item, true);


      const specificItem = items.find(existingItem => existingItem.id === item.id);
      if (specificItem) {
        item.name = specificItem.name;
      }
    },
    async onUpdateCaseIsChoosen(item) {
      const items = await this.fetchCases(item, true);


      const specificItem = items.find(existingItem => existingItem.id === item.id);
      if (specificItem) {
        item.card_assigned = specificItem.card_assigned;
      }
    },
    async onUpdateCasePanel(item) {
      const items = await this.fetchCases(item, true);


      const specificItem = items.find(existingItem => existingItem.id === item.id);
      if (specificItem) {
        item.name = specificItem.name;
        item.steps = specificItem.steps;
        item.results = specificItem.results;
      }
    },
    getData(item, type) {
      if (type == "suit") {
        item.children = [];
      }
      item.type = type;
      return item;
    },
    onAddTestSuit(item) {
      this.testSuitDialog.type = "create";
      this.testSuitDialog.item = item;
      this.testSuitDialog.visible = true;
    },
    onAddTestCase(item) {
      this.testCaseDialog.type = "create";
      this.testCaseDialog.item = item;
      this.testCaseDialog.visible = true;
    },
  },
};
