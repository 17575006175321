import * as mutationTypes from './types/mutations';
import { DEFAULT_STORAGE } from "@/mixins/constants";

export default {
  [mutationTypes.SET_COPIED_DATA]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("tracker-data") !== null) {
      data = JSON.parse(localStorage.getItem("tracker-data"));
    }
    data.copiedListData = value;
    localStorage.setItem("tracker-data", JSON.stringify(data));

    state.copiedListData = value;
  },
  [mutationTypes.SET_COPIED_ROUTES]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("tracker-data") !== null) {
      data = JSON.parse(localStorage.getItem("tracker-data"));
    }
    data.copiedListRoutes = value;
    localStorage.setItem("tracker-data", JSON.stringify(data));

    state.copiedListRoutes = value;
  },
  [mutationTypes.SET_COPIED_UUID]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("tracker-data") !== null) {
      data = JSON.parse(localStorage.getItem("tracker-data"));
    }
    data.copiedUuid = value;
    localStorage.setItem("tracker-data", JSON.stringify(data));

    state.copiedUuid = value;
  },
  [mutationTypes.SET_COPIED_PROJECT]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("tracker-data") !== null) {
      data = JSON.parse(localStorage.getItem("tracker-data"));
    }
    data.copiedProject = value;
    localStorage.setItem("tracker-data", JSON.stringify(data));

    state.copiedProject = value;
  },
  [mutationTypes.SET_COPIED_UUID_BOARD]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("tracker-data") !== null) {
      data = JSON.parse(localStorage.getItem("tracker-data"));
    }
    data.copiedUuidBoard = value;
    localStorage.setItem("tracker-data", JSON.stringify(data));

    state.copiedUuidBoard = value;
  },
};
