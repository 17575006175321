import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import theme from "@/store/modules/theme";
import language from "@/store/modules/language";
import notifications from "@/store/modules/notifications";
import userKey from "@/store/modules/usersKey";
import loader from "./modules/loader";
import activeList from "./modules/activeList";
import sideBarValues from "@/store/modules/sideBarValues";
import comments from "@/store/modules/comments";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    theme,
    language,
    notifications,
    userKey,
    loader,
    sideBarValues,
    activeList,
    comments
  },
});
