import user from "@/mixins/user";
import store from "@/store";
import * as actionTypes from "@/store/modules/sideBarValues/types/actions";

export default {
  data: () => ({}),
  mounted() {
    this.renderMenu();
  },
  mixins: [user],
  methods: {
    renderMenu() {
      if (this.copyListData) {
        // console.log(this.copyListData);
        this.listData = this.copyListData.map((item) => ({
          ...item,
          title: this.$t("menu.home"),
          activator_data: item.activator_data
            ? {
                ...item.activator_data,
                title: this.$t("menu.boards"),
              }
            : null,
        }));
      } else {
        this.listData = [
          {
            permission: true,
            icon: "mdi-home",
            navigate_name: "home",
            title: this.$t("menu.home"),
          },
          {
            permission: true,
            icon: "mdi-view-dashboard",
            value: this.boardSelector,
            activator_data: {
              icon: "mdi-view-dashboard-variant",
              title: this.$t("menu.boards"),
            },
            nested_data: [],
          },
        ];
      }
    },
  },
  watch: {
    boards: {
      handler(e) {
        if (e) {
          this.listData[1].nested_data = [];
          this.boards.forEach((board) => {
            this.listData[1].nested_data.push({
              permission: true,
              navigate_name: board.name.replaceAll(" ", "-"),
              icon: "mdi-view-dashboard",
              title: board.name,
            });
          });
          store.dispatch(
            `sideBarValues/${actionTypes.SET_COPIED_DATA}`,
            this.listData
          );
        }
      },
    },
  },
};
