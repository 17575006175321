<template>
  <VMenu offset-y v-model="shown">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" style="z-index: 1000">
        <span class="d-flex align-center">
          <div class="d-flex align-center ml-2">
            <t-avatar :user="getUser" />
            <span class="ml-3">
              {{ getUserProperty("username") }}
            </span>
          </div>
          <VIcon color="grey" v-if="attrs['aria-expanded'] === 'false'"
            >mdi-menu-down</VIcon
          >
          <VIcon
            color="grey"
            :class="!$vuetify.breakpoint.smAndDown ? '' : ''"
            v-else-if="attrs['aria-expanded'] === 'true'"
            >mdi-menu-up</VIcon
          >
        </span>
      </span>
    </template>
    <VCard>
      <VList @click.stop="">
        <VListItem>
          <VListItemContent>
            <VListItemTitle>{{ getUserProperty("username") }}</VListItemTitle>
            <VListItemSubtitle class="copy-text" @click="copyText(getUserProperty('email'))">{{
              getUserProperty("email")
            }}</VListItemSubtitle>
          </VListItemContent>
        </VListItem>

        <VDivider />
        <VListItem
          @click="navigateTo('settings')"
          :disabled="isCurrentRoute('settings')"
        >
          <VListItemTitle>
            <VIcon left>mdi-cog</VIcon>
            {{ $t("btn.settings") }}
          </VListItemTitle>
        </VListItem>
        <VDivider />
        <VListItem @click="onLogOut">
          <VListItemTitle>
            <VIcon left color="error">mdi-logout</VIcon>
            <span class="text-error">{{ $t("btn.logout") }}</span>
          </VListItemTitle>
        </VListItem>
      </VList>
    </VCard>
  </VMenu>
</template>

<script>
import { mapActions } from "vuex";
import * as actionTypes from "@/store/modules/usersKey/types/actions";
import user from "@/mixins/user";
import store from "@/store";
import * as actionType from "@/store/modules/sideBarValues/types/actions";
import * as actionTyp from "@/store/modules/activeList/types/actions";
import notifications from "@/mixins/notifications";

export default {
  name: "UserDropdown",
  mixins: [user, notifications],
  components: {},
  data: () => ({
    user: {},
    shown: false,
  }),
  methods: {
    ...mapActions("userKey", {
      setUserKey: actionTypes.SET_USER_KEY,
    }),
    ...mapActions("activeList", {
      setActiveList: actionTyp.SET_ACTIVE_LIST,
      setActiveListTab: actionTyp.SET_ACTIVE_LIST_TAB,
    }),
    isCurrentRoute(routeName) {
      return this.$route.name === routeName;
    },
    navigateTo(elem) {
      if (!this.isCurrentRoute(elem)) {
        this.shown = false;
        this.$router.push({ name: "SettingUser" }).catch(() => {});
      }
    },
    async onLogOut() {
      window.stop();
      await this.logOut();
      this.setUserKey(null);
      this.setActiveList("all");
      this.setActiveListTab(0);
      this.$router.push(this.$localize({ name: "login" })).catch(() => {});
      store.dispatch(`sideBarValues/${actionType.SET_COPIED_ROUTES}`, []);
      store.dispatch(`sideBarValues/${actionType.SET_COPIED_DATA}`, [
        {
          permission: true,
          icon: "mdi-home",
          navigate_name: "home",
          title: this.$t("menu.home"),
        },
      ]);
    },
    copyText(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
        },
        (err) => {
          this.setErrorNotification(err.message);
        }
      );
    },
    onSettings() {
      this.$emit("settings");
    },
  },
  computed: {},
  async mounted() {},
};
</script>

<style scoped>
.userAvatarDrop {
  width: 35px;
  height: 36px;
  object-fit: cover;
  border: thin solid #e3e3e3;
  border-radius: 4px;
}
.userAvatarDropList {
  width: 35px;
  height: 36px;
  object-fit: cover;
  border-radius: 4px;
}
.copy-text {
  text-decoration: underline;
  cursor: pointer;
}
</style>
