import cardsService from "@/services/request/cards/cardsService";
import { mapGetters } from "vuex";
import detailsStatus from "@/mixins/detailsStatus";
import notifications from "@/mixins/notifications";

export default {
  mixins: [detailsStatus, notifications],
  data: () => ({
    activitiesVisible: false,
    activitiesList: [],
    activityNewMessage: "",
    deleteCommentVisible: false,
    deleteCommentItem: {},
    originalDescription: "",
  }),
  mounted() {
    this.activitiesVisible = this.detailsStatus;
  },
  methods: {
    editComment(item) {
      item.editStatus = !item.editStatus;
      this.originalDescription = item.description;
    },
    async saveChangedComment(item) {
      if (this.originalDescription === item.description) {
        item.editStatus = false;
        return;
      }

      if (item.description.length) {
        try {
          const params = {};
          params.message = item.description;
          await cardsService.updateComment(
            this.board.project_id,
            this.board.id,
            this.task.list_id,
            this.task.id,
            item.id,
            params
          );
        } catch (e) {
          this.setErrorNotification(e.message);
        }
        item.editStatus = false;
      }
    },
    async GetActivityMessage() {
      try {
        const params = {};
        params.locale = this.currentLanguage.key;
        const response = await cardsService.GetmessageActive(
          this.board.project_id,
          this.board.id,
          this.task.list_id,
          this.task.id,
          params
        );
        if (!response || !Array.isArray(response.details)) {
          throw new Error("Invalid response format");
        }
        const activities = response.details;
        activities.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        this.activitiesList = activities.map((activity) => ({
          user: activity.user,
          username: activity.user.username,
          surname: "",
          date: new Date(activity.created_at).toLocaleString("uk-UA", {
            day: "2-digit",
            month: "short",
            hour: "2-digit",
            minute: "2-digit",
          }),
          description: activity.message,
          id: activity?.id,
          type: activity.type,
          editStatus: false,
        }));
      } catch (error) {
        console.error("Error getting activity messages:", error);
      }
    },
    async activityAddMessage() {
      if(this.activityNewMessage.trim() === "") {
        return;
      }
      this.setCardLoading(true);
      const formData = new FormData();
      formData.append("message", this.activityNewMessage);
      try {
        const response = await cardsService.PostMessageActive(
          this.board.project_id,
          this.board.id,
          this.task.list_id,
          this.task.id,
          formData
        );
        const newItem = {
          name: response.comment.user_id,
          date: new Date(response.comment.created_at).toLocaleString("uk-UA", {
            day: "2-digit",
            month: "short",
            hour: "2-digit",
            minute: "2-digit",
          }),
          description: response.comment.message,
        };
        this.activitiesList.unshift(newItem);
        this.activityNewMessage = "";
      } catch (error) {
        console.error("Error posting message:", error);
      } finally {
        await this.GetActivityMessage();
        this.setCardLoading(false);
      }
      if (this.activitiesVisible == false) {
        this.activitiesVisible = true;
        await this.GetActivityMessage();
        this.$nextTick(() => {
          if (this.activitiesVisible) {
            this.scrollToFirstComment();
          }
        });
      }
    },
    scrollToFirstComment() {
      const firstComment = this.$refs.firstActivity;
      if (firstComment && firstComment[0]) {
        firstComment[0].scrollIntoView({ behavior: "smooth", block: "end" });
      }
    },
    async activityDeleteMessage() {
      this.setCardLoading(true);
      try {
        await cardsService.DeleteMessageActive(
          this.board.project_id,
          this.board.id,
          this.task.list_id,
          this.task.id,
          this.deleteCommentItem.id
        );
      } catch (e) {
        console.log(e);
      } finally {
        await this.GetActivityMessage();
        this.setCardLoading(false);
        this.deleteCommentVisible = false;
      }
    },
    async handleActivityClick() {
      this.activitiesVisible = !this.activitiesVisible;
      this.setDetailsStatus(this.activitiesVisible);
      await this.GetActivityMessage();
      const scrollMessage = this.$refs.scrollTargetMessage;
      if (scrollMessage) {
        scrollMessage.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
  },
};
