<template>
  <v-dialog
    v-model="visibility"
    max-width="500"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    persistent
  >
    <v-card min-height="160">
      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title class="justify-center pt-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="addNewStep()"
              color="orange"
              dense
              text
              outlined
              absolute
              v-bind="attrs"
              v-on="on"
              style="top: 8px; left: 5px"
              ><v-icon size="28">mdi-clipboard-multiple-outline</v-icon></v-btn
            >
          </template>
          {{ $t("btn.add") }}
        </v-tooltip>
        <span>{{ $t("boards.steps.step_title") }}</span>
      </v-card-title>
      <div class="px-2" style="position: relative">
        <v-divider class="mb-4"></v-divider>
        <v-progress-linear
          v-if="!steps.length"
          indeterminate
          color="orange"
          top
          absolute
        ></v-progress-linear>
      </div>
      <v-card-text>
        <v-slide-x-transition>
          <v-row
            v-if="steps.length"
            style="max-height: 290px; overflow-y: auto"
          >
            <v-col
              cols="12"
              v-for="(step, index) in steps"
              :key="step.id"
              class="pa-1 d-flex align-center"
            >
              <v-col cols="auto" class="pa-0">
                <span
                  :style="$vuetify.theme.dark ? '#000' : '#fff'"
                  class="font-weight-bold text-body-1 mr-2"
                  >{{ index + 1 + ")" }}</span
                ></v-col
              >
              <v-col cols="auto" class="pa-0" style="flex-grow: 1">
                <v-text-field
                  :placeholder="$t('boards.steps.step')"
                  color="orange"
                  dense
                  outlined
                  hide-details
                  :maxLength="40"
                  v-model="steps[index].name"
                  class="steps-textfield"
                >
                  <template v-slot:append>
                    <v-scroll-x-transition>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="error"
                            @click="deleteStep(index)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-clipboard-minus-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("btn.delete") }}</span>
                      </v-tooltip>
                    </v-scroll-x-transition>
                  </template></v-text-field
                ></v-col
              >
            </v-col>
          </v-row>
          <div
            v-else
            class="text-center font-weight-bold text-body-1 add-first-step"
          >
            {{ $t("boards.steps.add_first_step") }}
          </div>
        </v-slide-x-transition>
      </v-card-text>
      <v-scroll-y-reverse-transition>
        <v-card-actions v-if="steps.length" class="pt-0"
          ><v-btn
            block
            color="orange"
            class="text-white"
            :disabled="isDisabled"
            ><v-icon>mdi-content-save</v-icon>{{ $t("btn.save") }}</v-btn
          ></v-card-actions
        >
      </v-scroll-y-reverse-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
export default {
  data: () => ({
    steps: [],
  }),
  mixins: [dialogMixin],
  methods: {
    onCancel() {
      this.$emit("close");
      this.steps = [];
    },
    addNewStep() {
      this.steps.push({
        id: this.steps.length + 1,
        name: "",
      });
    },
    deleteStep(index) {
      this.steps.splice(index, 1);
    },
  },
  computed: {
    isDisabled() {
      return !this.steps.every((step) => step.name.trim() !== "");
    },
  },
};
</script>

<style lang="scss">
.add-first-step {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translateX(50%) translateY(50%);
}
.steps-textfield {
  .v-input__control {
    .v-input__slot {
      .v-input__append-inner {
        margin-top: 3px !important;
      }
    }
  }
}
</style>
