import { mapActions, mapGetters } from "vuex";
import * as actionTypes from "@/store/modules/theme/types/actions";
import * as getterTypes from "@/store/modules/theme/types/getters";
import { THEMES } from "./constants";

export default {
  data: () => ({
    THEMES: [
      {
        text: THEMES.LIGHT,
        icon: "mdi-weather-sunny",
      },
      {
        text: THEMES.DARK,
        icon: "mdi-weather-night",
      },
      {
        text: THEMES.SYSTEM,
        icon: "mdi-theme-light-dark",
      },
    ],
  }),
  methods: {
    ...mapActions("theme", {
      setIsDark: actionTypes.SET_IS_DARK,
      setChosenTheme: actionTypes.SET_CHOSEN_THEME,
    }),
    onChangeSelectedTheme(themeName) {
      this.$nextTick(function () {
        if (themeName === "system") {
          this.$vuetify.theme.dark = window.matchMedia(
            "(prefers-color-scheme: dark)"
          ).matches;
        } else {
          this.$vuetify.theme.dark = themeName == "dark";
        }
      });
      this.$forceUpdate();
      this.setIsDark(this.$vuetify.theme.dark);
      this.setChosenTheme(themeName);
    },
  },
  computed: {
    ...mapGetters("theme", {
      isDark: getterTypes.IS_DARK,
      getChosenTheme: getterTypes.CHOSEN_THEME,
    }),
    getTheme() {
      return this.$vuetify.theme.isDark ? THEMES.DARK : THEMES.LIGHT;
    },
    isDark() {
      return this.$vuetify.theme.isDark;
    },
  },
};
