<template>
  <div style="" class="notification__wrapper">
    <div
      v-for="(notification, index) in firstNotifications"
      :key="notification.id"
    >
      <Notification :index="index" :notification="notification" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/notifications/types/getters";
import Notification from "./Notification.vue";

export default {
  name: "Notifications",
  components: {
    Notification,
  },
  computed: {
    ...mapGetters("notifications", {
      notifications: getterTypes.GET_NOTIFICATIONS,
    }),
    firstNotifications() {
      const maxNotifications = 5;
      const notifications = [];
      Object.keys(this.notifications).forEach((key) => {
        if (notifications.length < maxNotifications) {
          notifications.push(this.notifications[key]);
        }
      });
      return notifications;
    },
  },
};
</script>

<style scoped>
.notification__wrapper {
  position: fixed;
  width: 100%;
  z-index: 99999;
  pointer-events: none;
  height: 100vh;
  height: 100%;
}
</style>
