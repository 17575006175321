import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        green: "#4FAE3B",
        gray: "#3D3D3D",
        error: "#D32F2F",
        green_light: "#cef2c8",
        orange: "#FF7A00",
      },
      dark: {
        error: "#D32F2F",
        orange: "#FF7A00",
      },
    },
    options: {
      customProperties: true,
    },
  },
});
