<template>
  <v-dialog content-class="cross__dialog" v-model="visible" max-width="750px" min-height="350px" persistent @click:outside="$emit('close')">
    <v-card>
      <v-fade-transition>
        <v-progress-linear v-if="loading" absolute top indeterminate color="orange"></v-progress-linear>
      </v-fade-transition>

      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title class="justify-center">
        {{ $t("form.testcasetitle") }}
        <v-btn
            style="position: absolute; left: 15px; top: 15px"
            class="btn-orange-bg"
            @click="openTestCasesDialog"
        >{{ $t("form.testCases.addTestCase") }}</v-btn
        ></v-card-title>
      <v-card-text class="task-list-container">
        <v-row style="height: 400px; overflow-y: auto; overflow-x: hidden;" justify="space-between" align="start" class="ma-0 pa-0" v-if="type === 'edit' && selectedTestCases">
          <v-col class="mt-5 mx-0 mb-0 pa-0" cols="12">
            <v-row v-for="(tcase) in selectedTestCases" :key="tcase.id" class="align-center ma-n3 pa-0 d-flex justify-center">
              <v-col cols="7" class="py-0">
                  <span class="mr-3">{{ tcase.test_case.number }}</span>
                  <span class="font-weight-bold font-18">
                    {{ tcase?.test_case?.name ? tcase?.test_case?.name : tcase?.name }}
                  </span>
              </v-col>
              <v-col cols="3" class="pa-0 ma-0 d-flex align-center justify-center">
                <p style="text-transform: capitalize;" class="ma-0 pa-0" :class="SetColorCase(tcase.state)">{{ tcase.state }}</p>
              </v-col>
              <v-col cols="1" class="d-flex justify-start pa-0 ma-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="orange"
                        @click="runTestCase(tcase)"
                    >
                      <v-icon size="24">mdi-play</v-icon>
                    </v-btn>
                  </template>
                  <span> {{ 'Run test case' }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="1" class="d-flex justify-start px-0">
                <v-btn icon @click="removeTestCase(tcase.id)">
                  <v-icon small color="red">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-row v-if="!loading && currentPage < lastPage">
            <div ref="sentinel" class="observer"></div>
          </v-row>
        </v-row>
        <v-row v-else style="height: 400px;" justify="center" class="mb-1 mt-3">
          <v-col cols="auto">{{ $t("table.noData") }}</v-col>
        </v-row>

      </v-card-text>

      <v-col cols="12" class="ma-0 pr-4 py-2 pl-0 d-flex justify-end align-center">
      <div v-for="(state, index) in stateCounts" :key="index">
        <div :class="SetColorCase(state.name)" class="mx-2" style="font-weight: 700; text-transform: capitalize;">
          {{ state.name }}: {{ state.count }}
        </div>
      </div>
      <div class="mx-2" style="font-weight: 700;">
        {{ $t("form.total") }} {{ totalTestCases }}
      </div>
    </v-col>


    </v-card>
    <runTestCaseDialog
        v-if="runTestCaseDialog.visible"
        :visible="runTestCaseDialog.visible"
        :testCase="runTestCaseDialog.item"
        :projectId="copiedUuid"
        :task="task"
        :boardId="copiedUuidBoard"
        @updateCardTestCase="updateCardTestCase"
        @close="runTestCaseDialog.visible = false"
    />
    <TestCasesComponent
        :visible="testcaseDialog"
        @close="testcaseDialog = false"
        v-if="testcaseDialog"
        :projectId="copiedUuid"
        :task="task"
        :type="'fromTask'"
    />
    <ConfirmDialog
        v-if="removeTestCaseVisible"
        :visible="removeTestCaseVisible"
        @close="removeTestCaseVisible = false"
        @onConfirm="removeTestCaseFromTask()"
        @onCancel="removeMethod"
        :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("form.deleteCardDialog.delete_dialog_title") }}
      </template>
      <template v-slot:description>
        {{ $t("form.deleteCardDialog.delete_dialog_desc") }}
      </template></ConfirmDialog
    >
  </v-dialog>
</template>

<script>
import cardsService from '@/services/request/cards/cardsService';
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/sideBarValues/types/getters";
import RunTestCaseDialog from "@/components/boards/dialogs/runTestCaseDialog.vue";
import TestCasesComponent from "@/components/testCases/testCasesComponent.vue";
import EventBus from "@/events/EventBus";
import notifications from "@/mixins/notifications";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";

export default {
  components: {ConfirmDialog, TestCasesComponent, RunTestCaseDialog},
  mixins: [notifications],
  data() {
    return {
      runTestCaseDialog: {
        visible: false,
        item: {},
      },
      removeTestCaseVisible: false,
      testCaseId: "",
      selectedTestCases: [],
      loading: false,
      testcaseDialog: false,
      currentPage: 1,
      lastPage: null,
      isLoadingMore: false,
      returnCheckBox: true,
    };
  },
  props: {
    visible: Boolean,
    task: {
      required: false
    },
    type: {
      required: false
    },
  },
  async mounted() {
    await this.loadTestCases();
    EventBus.$on("removeTestCaseFromTask", (itemId) => {
      this.removeTestCase(itemId)
    });
    EventBus.$on("chooseNewTestCase", (choosenTestCase) => {
      this.selectedTestCases.unshift(choosenTestCase);
    });
  },
  methods: {
    initializeObserver() {
      const options = {
        root: null,
        threshold: 0.5,
      };
      this.observer = new IntersectionObserver(this.loadMoreTestCases, options);
      this.$nextTick(() => {
        const sentinel = this.$refs.sentinel;
        if (sentinel) {
          this.observer.observe(sentinel);
        }
      });
    },
    async loadMoreTestCases(entries) {
      const entry = entries[0];
      if (entry.isIntersecting && this.currentPage < this.lastPage) {
        await this.loadTestCases(this.currentPage + 1);
      }
    },
    async loadTestCases(page = 1) {
      if (this.isLoadingMore) return;
      this.isLoadingMore = true;
      try {
        const responseData = await cardsService.getTestCasesOnCard(
          this.copiedUuid,
          this.board?.id ? this.board?.id : this.copiedUuidBoard,
          this.task.list_id,
          this.task.id,
          { page }
        );
        const data = responseData?.data || [];
        const pagination = responseData?.pagination || {};
        this.selectedTestCases = page === 1 ? data : [...this.selectedTestCases, ...data];
        this.currentPage = pagination.meta?.current_page || 1;
        this.lastPage = pagination.meta?.last_page || 1;
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.isLoadingMore = false;
      }
    },

    SetColorCase(value) {
      switch (value) {
        case 'new':
          return 'text-new';
        case 'in progress':
          return 'text-warning';
        case 'failed':
          return 'text-error';
        case 'blocked':
          return 'text-blocked';
        case 'passed':
          return 'text-success';
        default:
          return 'text-body';
      }
    },
    removeTestCase(itemId) {
      this.testCaseId = itemId.card_assigned.id;
      this.returnCheckBox = itemId;
      this.removeTestCaseVisible = true;
    },
    openTestCasesDialog() {
        this.testcaseDialog = true;
    },
    removeMethod() {
      let ret = this.returnCheckBox.is_assigned = true;
      this.removeTestCaseVisible = false;
      return ret;
    },
    updateCardTestCase(testCase) {
      this.selectedTestCases = this.selectedTestCases.map(item => {
        if (item.id === testCase.id) {
          return testCase;
        }
        return item;
      });
    },
    runTestCase(item) {
      this.runTestCaseDialog.item = item;
      this.runTestCaseDialog.visible = true;
    },
    async removeTestCaseFromTask() {
      try {
        await cardsService.deleteTestCaseFromCard(
            this.copiedUuid,
            this.copiedUuidBoard,
            this.task.list_id,
            this.task.id,
            this.testCaseId
        );
        this.selectedTestCases = this.selectedTestCases.filter(testCase => testCase.id !== this.testCaseId);
        this.removeTestCaseVisible = false;
        this.setSuccessNotification(this.$t("profile.successfully_deleted"));
      } catch (e) {
        console.log(e)
        this.setErrorNotification(e.message);
      }
    },
  },
  computed: {
    stateCounts() {
      const counts = {};
      this.selectedTestCases.forEach((dcase) => {
        if (!counts[dcase.state]) {
          counts[dcase.state] = 0;
        }
        counts[dcase.state]++;
      });
      return Object.keys(counts).map((state) => ({
        name: state,
        count: counts[state],
      }));
    },
    totalTestCases() {
      return this.stateCounts.reduce((total, state) => total + state.count, 0);
    },
    ...mapGetters("sideBarValues", {
      copiedUuid: getterTypes.GET_COPIED_UUID,
      copiedUuidBoard: getterTypes.GET_COPIED_UUID_BOARD,
    }),
    ...mapGetters(["currentLanguage"]),
  },
  watch: {
    selectedTestCases() {
      this.initializeObserver();
    },
  },
  
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>

<style scoped>
.v-list-item--active  {
  color: orange !important;
}
.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.title-text {
  text-align: center;
  margin-left: 50px;
  font-size: 1rem;
}
.top-left-btn {
  position: absolute;
  left: 20px;
}
.task-list-container {
  border-top: 1px solid #e0e0e0;
  padding-top: 16px;
}
.text-success {
  color: rgb(143, 253, 167);
}
.text-warning {
  color: rgb(253, 186, 118);
}
.text-error {
  color: rgb(211, 55, 78);
}
.text-blocked {
  color: rgb(104, 189, 218);;
}
.text-new {
  color: rgb(110, 110, 110);
}
.text-body {
  color: rgb(110, 110, 110);
}
</style>
