<template>
  <div>
    <v-row
      no-gutters
      class="mb-2 mt-4 ml-2"
      justify="space-between"
      align="center"
    >
      <v-col cols="auto" class="d-flex align-center">
        <v-icon
          style="margin-right: 6px"
          :color="$vuetify.theme.dark ? '#F8F8FF' : '#1E1E1E'"
          size="22"
          >mdi-attachment</v-icon
        >
        <span class="task-desc-title">
          {{ $t("form.taskDescriptionDialog.Attachments") }}</span
        >
      </v-col>
      <v-col cols="auto">
        <v-file-input
          ref="fileInput"
          :label="$t('form.taskDescriptionDialog.addfile')"
          @change="handleFileChange"
          style="display: none"
        ></v-file-input>
        <v-btn class="btn-orange-bg" @click="triggerFileInput">{{
          $t("btn.add")
        }}</v-btn>
      </v-col>
    </v-row>
    <v-divider v-if="attachments.length" />
    <v-card-text class="pt-1 px-0 pb-4">
      <v-row class="mt-0">
        <v-col
          v-for="attachment in attachments"
          :key="attachment.id"
          cols="12"
          class="py-1"
        >
          <div class="attachment-item">
            <div
              v-if="attachment.file_path && isImage(attachment.file_path)"
              class="attachment-content"
            >
              <img
                :src="attachment.file_path"
                class="attachment-thumbnail"
                @click="openInNewTab(attachment.file_path)"
              />
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 100px;
                  padding-top: 5px;
                  justify-content: space-between;
                "
              >
                <div class="d-flex align-center">
                  <t-avatar :user="getUserData(attachment)" />
                  <span class="px-1"
                    ><span class="font-weight-medium mr-1">{{
                      getUserData(attachment).username
                    }}</span
                    >{{ $t("form.taskDescriptionDialog.attached") }}</span
                  >
                  <span class="attachment-date">{{
                    formatDateTime(attachment.created_at)
                  }}</span>
                </div>
                <div>
                  <v-btn
                    small
                    elevation="0"
                    class="v-custom-tonal-btn--orange mr-2"
                    @click="
                      selectCoverImage(attachment.file_path, attachment.id)
                    "
                    :loading="attachment.loading"
                  >
                    <v-icon size="20" class="mr-1">mdi-image-area</v-icon>
                    <span style="text-transform: none">
                      {{ $t("form.taskDescriptionDialog.made_cover_image") }}
                    </span></v-btn
                  >
                  <v-btn
                    @click="removeAttachment(attachment)"
                    small
                    class="v-custom-tonal-btn--error"
                    elevation="0"
                    :loading="attachment.loading"
                    style="text-transform: none"
                    ><v-icon class="text-white" size="20" color="error"
                      >mdi-trash-can-outline</v-icon
                    >{{ $t("btn.delete") }}</v-btn
                  >
                </div>
              </div>
            </div>
            <div
              v-else-if="attachment.file_path && isVideo(attachment.file_path)"
              class="attachment-content"
            >
              <v-img
                class="attachment-thumbnail"
                @click="openInNewTab(attachment.file_path)"
              >
                <template v-slot:placeholder>
                  <v-row
                    no-gutters
                    class="pa-0 d-flex align-center justify-center"
                    style="height: 100px; width: 100px"
                    ><v-icon
                      size="64"
                      :color="$vuetify.theme.dark ? '#F8F8FF' : '#1E1E1E'"
                      >mdi-movie-open</v-icon
                    ></v-row
                  >
                </template></v-img
              >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 100px;
                  padding-top: 5px;
                  justify-content: space-between;
                "
              >
                <div class="d-flex align-center">
                  <t-avatar :user="getUserData(attachment)" />
                  <span class="px-1"
                    ><span class="font-weight-medium mr-1">{{
                      getUserData(attachment).username
                    }}</span
                    >{{ $t("form.taskDescriptionDialog.attached") }}</span
                  >
                  <span class="attachment-date">{{
                    formatDateTime(attachment.created_at)
                  }}</span>
                </div>
                <div>
                  <v-btn
                    @click="removeAttachment(attachment)"
                    small
                    class="v-custom-tonal-btn--error"
                    elevation="0"
                    style="text-transform: none"
                    ><v-icon class="text-white" size="20" color="error"
                      >mdi-trash-can-outline</v-icon
                    >{{ $t("btn.delete") }}</v-btn
                  >
                </div>
              </div>
            </div>
            <div v-else-if="attachment.file_path" class="attachment-content">
              <a
                :href="attachment.file_path"
                style="
                  text-decoration: none;
                  font-size: 18px;
                  display: flex;
                  height: 44px;
                  width: 100px;

                  align-items: center;
                  justify-content: center;
                  margin-right: 10px;
                "
                :style="!$vuetify.theme.dark ? 'color: #4B5262' : 'color: #fff'"
              >
                <v-row class="d-flex flex-column ml-2 mr-4 align-center">
                  <v-icon
                    size="44"
                    :color="$vuetify.theme.dark ? '#F8F8FF' : '#1E1E1E'"
                    >mdi-file-document-outline</v-icon
                  >
                  <span>{{ "File" }}</span>
                </v-row>
              </a>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 80px;
                  padding-top: 5px;
                  justify-content: space-between;
                "
              >
                <div class="d-flex align-center">
                  <t-avatar :user="getUserData(attachment)" />
                  <span class="px-1"
                    ><span class="font-weight-medium mr-1">{{
                      getUserData(attachment).username
                    }}</span
                    >{{ $t("form.taskDescriptionDialog.attached") }}</span
                  >
                  <span class="attachment-date">{{
                    formatDateTime(attachment.created_at)
                  }}</span>
                </div>
                <div>
                  <v-btn
                    @click="removeAttachment(attachment)"
                    small
                    class="v-custom-tonal-btn--error"
                    elevation="0"
                    style="text-transform: none"
                    ><v-icon class="text-white" size="20" color="error"
                      >mdi-trash-can-outline</v-icon
                    >{{ $t("btn.delete") }}</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
          <v-divider class="mt-2" />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";
import cardsService from "@/services/request/cards/cardsService";
import EventBus from "@/events/EventBus";

export default {
  mixins: [notifications],
  data() {
    return {
      attachments: [],
      newFile: null,
      videoThumbnail: "path/to/video-thumbnail.jpg",
      loading: false,
    };
  },
  props: {
    inputTask: {
      type: Object,
    },
    board: {
      type: Object,
    },
    users: {
      required: true,
      default: [],
    },
  },
  created() {
    EventBus.$on("attachment-pasted", (file) => {
      this.newFile = file;
      this.addAttachment();
    });
  },
  beforeDestroy() {
    EventBus.$off("attachment-pasted");
  },
  async mounted() {
    await this.fetchAttachments();
  },
  methods: {
    async selectCoverImage(filePath, attachmentId) {
      const imageExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "webp",
        "tiff",
      ];
      const searchItemIndex = this.attachments.findIndex(
        (item) => item.id === attachmentId
      );
      this.$set(this.attachments[searchItemIndex], "loading", true);
      const fileExtension = filePath.split(".").pop().toLowerCase();
      if (imageExtensions.includes(fileExtension)) {
      } else {
        this.setErrorNotification(this.$t("form.errors.Selectedimageerror"));
        return;
      }
      try {
        EventBus.$emit("is-selected-image", filePath);
        const params = {};
        params.attachment_id = attachmentId;
        await cardsService.changeCardCover(
          this.board.project_id,
          this.board.id,
          this.inputTask.list_id,
          this.inputTask.id,
          params
        );
        this.$emit("selectCoverImage", filePath);
        this.$set(this.attachments[searchItemIndex], "loading", false);
        this.setSuccessNotification(
          this.$t("form.taskDescriptionDialog.Selectedimagesucces")
        );
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    async fetchAttachments() {
      this.loading = true;
      try {
        this.attachments = await cardsService.getAttachments(
          this.board.project_id,
          this.board.id,
          this.inputTask.list_id,
          this.inputTask.id
        );
        this.attachments.map((item) => (item.loading = false));
        this.attachments.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        this.$emit("changeTask");
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.loading = false;
      }
    },
    async addAttachment() {
      if (!this.newFile) return;
      this.$emit("setCardLoading", true);
      try {
        const formData = new FormData();
        formData.append("file", this.newFile);
        const response = await cardsService.addAttachment(
          this.board.project_id,
          this.board.id,
          this.inputTask.list_id,
          this.inputTask.id,
          formData
        );
        if (response) {
          this.attachments.push(response);
          this.attachments.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          this.newFile = null;
          this.$emit("changeTask");
        }
      } catch (e) {
        this.setErrorNotification(e.errors.file[0]);
      } finally {
        this.$emit("setCardLoading", false);
      }
    },
    handleFileChange(files) {
      if (files) {
        this.newFile = files;
        this.addAttachment();
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.$el.querySelector("input").click();
    },
    async removeAttachment(attachment) {
      const searchItemIndex = this.attachments.findIndex(
        (item) => item.id === attachment.id
      );
      this.$set(this.attachments[searchItemIndex], "loading", true);
      try {
        await cardsService.deleteAttachment(
          this.board.project_id,
          this.board.id,
          this.inputTask.list_id,
          this.inputTask.id,
          attachment.id
        );
        this.$set(this.attachments[searchItemIndex], "loading", false);
        this.attachments.splice(searchItemIndex, 1);
        this.newFile = null;
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|gif)$/i.test(url);
    },
    isVideo(url) {
      return /\.(mp4|webm|ogg)$/i.test(url);
    },
    openInNewTab(url) {
      window.open(url, "_blank");
    },
    downloadFile(url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = this.getFileName(url);
      link.click();
    },
    getFileName(url) {
      if (!url) return "";
      return url.split("/").pop();
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}.${month}.${year}, ${hours}:${minutes}`;
    },
    getUserData(item) {
      return this.users.find((user) => user.user.id === item.user_id).user;
    },
  },
};
</script>

<style scoped lang="scss">
.attachment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attachment-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.attachment-thumbnail {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  object-fit: cover;
  flex-grow: 0 !important;
  .theme--light & {
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  .theme--dark & {
    border: thin solid rgba(255, 255, 255, 0.12);
  }
}

.attachment-date {
  margin-right: auto;
}
</style>
