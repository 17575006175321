<template>
  <v-dialog
    v-model="visibility"
    max-width="360"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    @click:outside="onCancel()"
  >
    <v-card class="pb-4">
      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title class="pb-0">
        {{ $t("table.participantscard") }}</v-card-title
      >
      <v-divider class="mx-4"></v-divider>
      <v-card-text
        v-if="usersList.length"
        class="pb-0"
        style="max-height: 225px; overflow-y: auto"
      >
        <v-list>
          <v-list-item
            v-for="user in usersList"
            :key="user.id"
            color="orange"
            class="pl-0 pa-0"
          >
            <v-col cols="auto" class="py-2">
              <v-checkbox
                hide-details
                color="orange"
                v-model="user.is_assigned"
                @click.prevent="toggleStatus(user)"
              />
            </v-col>
            <v-col cols="auto" class="pa-1">
              <t-avatar :user="user.user" :size="32" />
            </v-col>
            <v-col cols="auto" style="flex-grow: 1" class="pl-1"
              ><span style="font-size: 16px" class="font-weight-medium">{{
                user.user.username
              }}</span></v-col
            >
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";
import user from "@/mixins/user";

export default {
  mixins: [dialogMixin, notifications, user],
  data: () => ({
    usersList: [],
    usersListCreate: [],
  }),
  props: {
    boardUsers: {
      required: true,
    },
    board: {
      required: true,
    },
    task: {
      required: true,
    },
    type: {
      required: true,
    },
  },
  mounted() {
    this.usersList = [...this.boardUsers];
    const currentUser = this.usersList.find(user => user.user.id === this.getUser.id);
    this.usersList = this.usersList.filter(user => user.user.id !== this.getUser.id);
    if (currentUser) {
      this.usersList.unshift(currentUser);
    }
    this.usersList.forEach((user) => {
      user.is_assigned = this.task.assigned_users.some(
        (item) => item.id === user.user.id
      );
    });
  },
  methods: {
    onCancel() {
      if (this.type === "edit") {
        this.$emit("close", "edit");
      } else {
        this.$emit(
          "close",
          "create",
          this.usersList.filter((item) => item.is_assigned == true)
        );
      }
    },
    toggleStatus(user) {
      if (this.type === "edit") {
        if (user.is_assigned) {
          this.$emit("addUserToCard", user.user);
        } else {
          this.$emit("deleteUserFromCard", user.user);
        }
      }
    },
  },
};
</script>
