<template>
  <v-dialog
    v-model="visibility"
    max-width="400"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    @click:outside="onCancel()"
  >
    <v-card>
      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title class="justify-center">{{
        type === "create" ? $t("boards.create_tag") : $t("boards.edit_tag")
      }}</v-card-title>
      <v-card-text class="pb-0 px-3">
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              hide-details
              color="orange"
              maxLength="30"
              v-model="inputLabel.name"
              :placeholder="$t('table.title')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row cols="12" justify="center">
          <v-color-picker
            v-model="inputLabel.color"
            hide-canvas
            show-swatches
            :swatches="colorsList"
            hide-sliders
            hide-inputs
          ></v-color-picker>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="type === 'edit'"
          block
          class="btn-orange-bg"
          :disabled="!changed"
          @click="onEdit()"
          >{{ $t("btn.edit") }}</v-btn
        >
        <v-btn
          v-if="type === 'create'"
          block
          class="btn-orange-bg"
          :disabled="!changed"
          @click="onCreate()"
          >{{ $t("btn.create") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";

export default {
  mixins: [dialogMixin, notifications],
  data: () => ({
    colorsList: [
      ["#F44336", "#FF5722", "#E91E63"],
      ["#673AB7", "#9C27B0", "#3F51B5"],
      ["#03A9F4", "#00BCD4", "#2196F3"],
      ["#33691E", "#4CAF50", "#8BC34A"],
      ["#FFC107", "#FF9800", "#FF7A00"],
    ],
    inputLabel: {},
    copyLabel: {},
    changed: false,
  }),
  props: {
    label: {
      required: true,
    },
    type: {
      required: true,
    },
  },
  mounted() {
    for (const key in this.label) {
      if (this.label[key] === null) {
        this.label[key] = "";
      }
    }
    this.inputLabel = { ...this.label };
    this.copyLabel = { ...this.label };
    if (this.type === "create") {
      this.inputLabel.color = "#673AB7";
      this.copyLabel.color = "#673AB7";
    }
  },
  methods: {
    onCancel() {
      this.inputLabel = {};
      this.$emit("close");
    },
    onCreate() {
      this.$emit("create-label", this.inputLabel);
      this.onCancel();
    },
    onEdit() {
      this.$emit("update-label", this.inputLabel);
      this.onCancel();
    },
  },

  watch: {
    inputLabel: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyLabel, this.inputLabel);
      },
    },
  },
};
</script>
