<template>
  <v-row class="d-flex align-center justify-center pb-3" no-gutters>
    <v-col class="d-flex align-center" cols="7">
      <v-menu
          ref="menuFirstDate"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          class="text-caption"
          left
          bottom
          offset-overflow

      >
        <template v-slot:activator="{ on, attrs }">
          <VTextField
              solo
              :placeholder="$t('form.time_created')"
              v-model="dateRangeText"
              readonly
              append-icon="mdi-calendar"
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
              style="width: 220px; padding-right: 15px;"
          >
            <template v-slot:append-outer>
              <v-slide-x-reverse-transition>
                <VIcon
                    style="position: relative; bottom: 4px;"
                    @click="onCancelInterval"
                    v-if="time_created_period.length >= 1"
                >mdi-close
                </VIcon
                >
              </v-slide-x-reverse-transition>
            </template>
          </VTextField>
        </template>
        <v-date-picker
            v-model="time_created_period"
            range
            no-title
            scrollable
            :locale="currentLanguage.key"
            :min="minDate"
            :max="maxDate"
            @input="onChangeDatePeriod"
        >
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data: () => ({
    dateMenu: false,
    time_created_period: [],
    dateRangeText: '',
    minDate: '',
    maxDate: '',
    filterProps: {
      date_created_begin: "",
      date_created_end: "",
    },
  }),
  mounted() {
    const today = new Date();
    const nextYear = new Date(today);
    const lastYear = new Date(today);
    nextYear.setFullYear(today.getFullYear() + 1);
    lastYear.setMonth(today.getMonth() - 12);

    this.minDate = lastYear.toISOString().substr(0, 10);
    this.maxDate = nextYear.toISOString().substr(0, 10);
    const savedDates = JSON.parse(localStorage.getItem("time_created_period"));
    if (savedDates) {
      this.time_created_period = savedDates;
      this.dateRangeText = this.time_created_period.join(' ~ ');
      this.filterProps.date_created_begin = this.time_created_period[0];
      this.filterProps.date_created_end = this.time_created_period[1];
    } else {
      const nearestMonday = new Date(today);
      nearestMonday.setDate(today.getDate() - (today.getDay() + 6) % 7);
      this.time_created_period = [
        nearestMonday.toISOString().substr(0, 10),
        today.toISOString().substr(0, 10),
      ];
      this.dateRangeText = this.time_created_period.join(' ~ ');
      this.filterProps.date_created_begin = this.time_created_period[0];
      this.filterProps.date_created_end = this.time_created_period[1];
    }
    this.$emit("callTheFuction", this.filterProps);
  },
  methods: {
    onChangeDatePeriod() {
      if (this.time_created_period.length === 2) {
        if (this.time_created_period[0] === this.time_created_period[1]) {
          this.filterProps.time_created = this.time_created_period[0];
          this.dateRangeText = this.time_created_period[0];
          this.time_created_period.splice(1, 1);
        } else {
          this.filterProps.time_created = "";
          const firstDate = new Date(this.time_created_period[0]);
          const secondDate = new Date(this.time_created_period[1]);
          if (firstDate > secondDate) {
            this.filterProps.date_created_begin = this.time_created_period[1];
            this.filterProps.date_created_end = this.time_created_period[0];
            this.dateRangeText = `${this.time_created_period[1]} ~ ${this.time_created_period[0]}`;
          } else {
            this.filterProps.date_created_begin = this.time_created_period[0];
            this.filterProps.date_created_end = this.time_created_period[1];
            this.dateRangeText = this.time_created_period.join(' ~ ');
          }
        }
        localStorage.setItem("time_created_period", JSON.stringify(this.time_created_period));
        this.onApplyFilter(this.filterProps);
      } else if (this.time_created_period.length === 1) {
        this.filterProps.date_created_begin = "";
        this.filterProps.date_created_end = "";
        this.dateRangeText = this.time_created_period[0];
        this.filterProps.time_created = this.time_created_period[0];
        this.onApplyFilter(this.filterProps);
      }
    },
    onApplyFilter(filterItems) {
      this.filterProps = {
        ...filterItems
      };
      this.$emit('callTheFuction', this.filterProps);
    },
    onCancelInterval() {
      const today = new Date();
      const nearestMonday = new Date(today);
      nearestMonday.setDate(today.getDate() - (today.getDay() + 6) % 7);

      this.time_created_period = [
        nearestMonday.toISOString().substr(0, 10),
        today.toISOString().substr(0, 10),
      ];
      this.dateRangeText = this.time_created_period.join(' ~ ');
      this.filterProps.date_created_begin = this.time_created_period[0];
      this.filterProps.date_created_end = this.time_created_period[1];

      localStorage.setItem("time_created_period", JSON.stringify(this.time_created_period));
      this.onApplyFilter(this.filterProps);
    },
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
  }
};

</script>