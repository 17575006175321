// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = "/test-suits";

export default {
  async getTestSuits(projectId, params) {
    const response = await requestService.get(
      `/projects/${projectId}${prefix}`, params
    );
    return response?.data?.data;
  },
  async getSingleTestSuit(projectId, suitId, params) {
    const response = await requestService.get(
      `/projects/${projectId}${prefix}/${suitId}`, params
    );
    return response?.data?.data;
  },
  async createTestSuit(projectId, payload) {
    const response = await requestService.post(
      `/projects/${projectId}${prefix}`,
      payload
    );
    return response?.data;
  },
  async updateTestSuit(projectId, suitId, payload) {
    const response = await requestService.post(
      `/projects/${projectId}${prefix}/${suitId}`,
        payload
    );
    return response?.data;
  },
  async deleteTestSuit(projectId, suitId) {
    const response = await requestService.delete(
      `/projects/${projectId}${prefix}/${suitId}`
    );
    return response?.data;
  },
  async getTestCase(projectId, suitId, params) {
    const response = await requestService.get(
      `/projects/${projectId}${prefix}/${suitId}/test-cases`,
        { ...params }
    );
    return response?.data?.data;
  },
  async createTestCase(projectId, suitId, payload) {
    const response = await requestService.post(
      `/projects/${projectId}${prefix}/${suitId}/test-cases`,
      payload
    );
    console.log(response)
    return response?.data?.data;
  },
  async updateTestCase(projectId, suitId, caseId, payload) {
    const response = await requestService.post(
        `/projects/${projectId}${prefix}/${suitId}/test-cases/${caseId}`,
        payload
    );
    return response?.data;
  },
  async deleteTestCase(projectId, suitId, caseId) {
    const response = await requestService.delete(
        `/projects/${projectId}${prefix}/${suitId}/test-cases/${caseId}`
    );
    return response?.data;
  },
  // Labels
  async addLabelToTestCase(projectId, suitId, caseId, payload) {
    const response = await requestService.post(
        `/projects/${projectId}${prefix}/${suitId}/test-cases/${caseId}/add-label`,
        payload
    );
    return response?.data;
  },
  async deleteLabelFromTestCase(projectId, suitId, caseId, payload) {
    const response = await requestService.post(
        `/projects/${projectId}${prefix}/${suitId}/test-cases/${caseId}/remove-label`,
        payload
    );
    return response?.data;
  },
};
