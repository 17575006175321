<template>
  <VDialog v-model="visibility" max-width="400" :transition="$dialogTransition">
    <VCard>
      <VCardTitle
        class="headline"
        :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-alert
          text
          dense
          type="error"
          class="w-100 v-custom-alert"
          :class="{ 'text-caption': $vuetify.breakpoint.xs }"
        >
          {{ $t("confirmDialog.accept_leave") }}</v-alert
        >
      </VCardTitle>
      <VCardText>
        <span
          class="text-left d-block"
          :class="{ 'text-caption': $vuetify.breakpoint.xs }"
        >
          {{ $t("confirmDialog.changesInfo") }}
        </span>
        <span
          class="text-left d-block mt-3"
          :class="{ 'text-caption': $vuetify.breakpoint.xs }"
        >
          {{ $t("confirmDialog.leaveInfo") }}
        </span>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="6">
            <VBtn
              color="primary"
              block
              dark
              text
              class="v-custom-tonal-btn--primary w-100"
              @click="visibility = false"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("confirmDialog.stay_btn") }}
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn
              color="error"
              block
              class="w-100"
              dark
              @click="onConfirm"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("confirmDialog.leave_btn") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: "ConfirmDeleteDialog",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    onConfirm() {
      this.$emit("delete", this.id);
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>
