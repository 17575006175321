// function notEngTxt(constant) {
//   return `Поле «${constant}»  повинне бути кирилицею!`;
// }
// function isRequired(constant) {
//   return `Поле «${constant}»  обов'язкове для заповнення!`;
// }
import menu from "./menu.json";
import home from "./home.json";
import table from "./table.json";
import confirmDialog from "./confirm-dialog.json";
import boards from "./boards.json";
import auth from "./auth.json";
import profile from "./profile.json";
import error from "./error.json";
import testCases from "./test-cases.json";
export default {
  menu: { ...menu },
  home: { ...home },
  table: { ...table },
  confirmDialog: { ...confirmDialog },
  boards: { ...boards },
  auth: { ...auth },
  profile: { ...profile },
  error: { ...error },
  testCases: { ...testCases },
  menuLocal: {
    home: "Головна",
    boards: "Дошки",
  },
  btn: {
    settings: "Налаштування",
    translate: "Мова",
    logout: "Вийти",
    add: "Додати",
    delete: "Видалити",
    update: "Оновити",
    create: "Створити",
    close: "Закрити",
    save: "Зберегти",
    back: "Назад",
    cancel: "Скасувати",
    apply: "Застосувати",
    confirm: "Підтвердити",
    edit: "Редагувати",
    all: "Всі",
    archiveRestore: "Відновити",
    addTime: "Додати час",
    selectTime: "Вибрати Час",
    HintTestCases: "Тест кейси",
    ActivityProjectDialog: "Активності",
    timeReport: "Звіт про час"
  },
  form: {
    userisundefined: "Поле не повинно бути порожнім",
    email: "E-mail",
    password: "Пароль",
    name: "Ім'я",
    surname: "Прізвище",
    phone_number: "Номер телефону",
    search: "Пошук",
    title: "Назва",
    description: "Опис",
    title_setting: "Налаштування",
    phone: "Номер телефону",
    Curpassword: "Поточний пароль",
    NewPass: "Новий пароль",
    Confirpassword: "Підтвердити палоль",
    SendPass: "Відправити код ще раз",
    CodeSuccess: "Код підтверджено",
    PassNotMatch: "Паролі не співпадають",
    PassSendEmail: "Код відновлення паролю відправлено на Email",
    NewPassSave: "Новий пароль збережено!",
    ChangePhoto: "Змінити фото",
    Tasks: "Завдання",
    BugText: "Баг",
    Profile: "Профіль",
    username: "Ім'я користувача",
    nickname: "Назва користувача",
    deluserboard: "Користувач успішно видалений",
    adduserboard: "Користувача успішно додано",
    cardname: "Назва картки: ",
    testcasetitle: "Тест кейси",
    Project_lable: "Проєкти",
    ProjectSearch: "Проєктів не знайдено",
    StanDate: "Днів в статусі",
    TypeTask: "Тип завдання",
    parentTaskLableTrue: "Батьківські",
    parentTaskLableFalse: "Небатьківські",
    parentTaskLableNull: "Всі завдання",
    timeError: "Увага! Вказана дата ще не настала.",
    projectName: "Назва проєкту",
    leaveTitle: "Покинути проєкт",
    leaveDescription: "Якщо ви покинете проєкт ви встратите всі дані зв'язані з цим проєктом.",
    AllYourTasks: "Всі ваші завдання",
    DataTimeDays: "[\"Нд\", \"Пн\", \"Вт\", \"Ср\", \"Чт\", \"Пт\", \"Сб\"]",
    BlockUserTimeTrue: "Запис часу розблоковано",
    BlockUserTimeFalse: "Запис часу заблоковано",
    TotalProgressParentTask: "Загальний прогресс",
    taskDescriptionDialog: {
      users: "Користувачі",
      tags: "Мітки",
      tagsSearch: "Пошук мітки",
      NameTag: "Назва мітки",
      SaveTag: "Створити мітку",
      CreateNewTag: "Створити нову мітку",
      backTag: "Назад до міток",
      TimeTextTask: "Час викон. год/хв",
      messages: "Повідомлення",
      finish_date: "Кінець",
      start_date: "Дата початку",
      date: "Дата",
      Description: "Опис",
      Watch: "Слідкувати",
      Subtasks: "Субтаски",
      addsubtasks: "Додати субтаску",
      addfile: "Додати файл",
      Additions: "Додатки",
      Attachments: "Вкладення",
      Activity: "Звіт про час",
      ShowDetails: "Показати деталі",
      HideDetails: "Приховати деталі",
      PostComment: "Написати коментар",
      status: "Статус",
      story_points: "Оцінка складності",
      Selectedimagesucces: "Обкладинку успішно оновлено",
      made_cover_image: "Зробити обкладинкою",
      attached: "прикріпив(-ла) в",
      archive_title: "Архівування",
      archive_desc: "Ви дійсно хочете архівувати завдання?",
      archiveRestore: "Відновити",
      testCases: "Тест кейси",
      openList: "Відкрити список",
      SelectTestCaseTitle: "Обрані тест кейси",
      duration: "Тривалість (Днів)",
      inputNameTask: "Введіть назву",
      selectboard: "Виберіть дошку",
      ParentCardsInfo: "Карта проєкту",
      ParentCard: "Батьківська картка",
      ParentCards: "Батьківські картки",
      time_activity: "Активність:",
      time_task: "Таска:",
      time: "Час:",
      time_other: "інше",
      date_created: "Дата створення",
      CreateMulti: {
        lable_text: "Створення завдання",
        comment_text_one: "Якщо ви хочете, ми можемо створити картку для кожного нового рядка",
        comment_text_two: "Ви також можете створити одну картку з довгою назвою.",
        btn_create_multi : "Створити завдання",
        btn_create_one: "Лише одне завдання",
      },
    },
    ParentTasks: {
      ProgressLable: "Редагування прогресу",
      ProgressTable: "Прогресс",
    },
    ConfirmParentTasks: {
      header: "Зміна статусу завдання",
      description_one: "Ви дійсно хочете змінити статус завдання на Виконано ?",
      description_two: "При зміні статусу це завдання не відображатиметься у цьому списку.",
    },
    deleteCardDialog: {
      DelBoard: "Видалити дошку",
      Confirm: "Підтвердження видалення",
      Textconfirm: "Для підтвердження видалення введіть назву дошки:",
      Text: "Назва дошки",
      error: "Неправильно введена назва",
      delete_dialog_title: "Видалення",
      delete_dialog_desc: "Ви справді хочете видалити це?",
    },
    archiveDialog: {
      archiveTitle: "Архів",
      archive_nameTask: "Назва задачі",
      archive_dataDelete: "Дата видалення"
    },
    History: {
      Page: "Сторінка",
    },
    errors: {
      nameRequired: "Ім'я обов'язкове",
      surnameRequired: "Прізвище обов'язкове",
      timeRequired: "Поле час обов'язкове",
      emailRequired: "Email обов'язковий",
      emailMustBeValid: "Email повинен бути валідним",
      emailalreadyExists: "Такий email вже існує.",
      nicknamereg: "Нікнейм обов'язковий",
      passwordRequired: "Пароль обов'язковий",
      passwordMustBeLike:
        "Виберіть надійніший пароль. Спробуйте комбінацію букв, цифр і символів",
      PhoneNumberRequired: "Телефон обов'язковий",
      PhoneNumberMustBeLike: "Телефон повинен містити: +(380)-000-000-000",
      photoMaxSize: "Фото повинно бути менше 8 МБ!",
      listAndNameRequired: "Список та назва обов'язкові",
      registerUsername: "Ім'я користувача вже зайнято.",
      Selectedimageerror: "Вибраний файл не є зображенням",
      loginError: "Ваші дані для входу невірні",
      required: "Поле обов'язкове для заповнення!",
    },
    checklistDialog: {
      addTestCase: "Додати тест-кейс",
      taskList: "Список завдань",
    }, 
    noData: "Даних немає!",
    time: "Час",
    generally: "Загалом",
    total: "Всього:",
    totall: "Всього",
    statisticBoard: "Статистика дошки",
    statisticLable: "Статистика",
    statisticTask : "Всього завдань",
    statisticParent: "Батьківські завдання",
    statisticBug: "Кількість помилок",
    statisticCount: "Кількість завдань", 
    statisticStory: "Бали історії",
    statisticExportLable: "Експорт",
    statisticSuccess: "Дані успішно експортовано",
    testCases: {
      selectTestCase: "Оберіть Тест кейс",
      addTestCase: "Додати тест кейс",
      addTestSuite: "Додати тест сют",
      edit: "Редагувати",
      SelectStatus: "Вибрати статус",
      createTestSuite: "Створити тестсют",
      editTestSuite: "Редагувати тестсют",
      willBeCreatedIn: "Буде створено у",
      createTestCase: "Створити тест кейс",
      editTestCase:"Редагувати тест кейс",
      createdin: "Буде створено у"
    },
    day_singular: "день",
    day_few: "дні",
    day_many:'днів',
  },
};
