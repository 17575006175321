// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const TOKEN_NAME = "app_access_token";

export default {
  setToken(token) {
    localStorage.setItem(TOKEN_NAME, token);
  },
  getToken() {
    return localStorage.getItem(TOKEN_NAME);
  },
  removeToken() {
    return localStorage.removeItem(TOKEN_NAME);
  },
  async logIn(payload) {
    const response = await requestService.post('/login', payload, {}, false);
    this.setToken(response?.data?.token);
    return response?.data?.user;
  },
  async register(payload) {
    const response = await requestService.post("/register", payload, {}, false);
    return response;
  },
  async logOut() {
    const response = await requestService.post("/logout");
    this.removeToken();
    return response;
  },
};
