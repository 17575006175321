<template>
  <v-dialog
    v-model="visibility"
    min-width="390"
    max-width="800"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    scrollable
    @click:outside="onCancel()"
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
          v-if="loading"
          absolute
          top
          indeterminate
          color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <div class="d-flex justify-end mx-6">
        <div :style="$vuetify.breakpoint.smAndUp ? ($vuetify.breakpoint.smAndDown ? 'z-index: 100; position: absolute; top: 45px; right: 140px;':'z-index: 100; position: absolute; top: 5px; left: 260px;'):'z-index: 100; position: absolute; top: 45px; right: 70px;'">
         <v-menu
           v-model="showUsersMenu"
           offset-y
           nudge-width="400"
           transition="slide-x-transition"
           
         >
           <template v-slot:activator="{ on, attrs }">
             <v-btn 
               small
               outlined
               color="orange" 
               class="ml-5 mt-5" 
               @click="usersListData"
               v-bind="attrs" 
               v-on="on"
               :style="$vuetify.breakpoint.smAndUp ? 'min-width: 105px; max-width: 105px;':'min-width: 35px; max-width: 35px;'"
             >
               <div v-if="$vuetify.breakpoint.smAndUp">{{ $t("form.taskDescriptionDialog.users") }}</div>
               <v-icon v-else size="20">mdi-account-group</v-icon>
             </v-btn>
           </template>
           <v-list style="max-height: 300px; overflow: auto;">
             <v-list-item 
               v-for="user in usersList" 
               :key="user.id"
               @click="selectUser(user)"
               :class="user.id == getUser.id ? 'yourAccount' : ''"
             >
              <v-list-item-avatar>
                <t-avatar :user="user" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-medium"
                  style="font-size: 15px; max-width: 400px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                >
                  {{ user.email }}
                </v-list-item-title>
              </v-list-item-content>
             </v-list-item>
           </v-list>
         </v-menu>
        </div>

        <v-menu offset-y v-model="showProjects" :close-on-content-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small outlined color="orange" class="ml-5 mt-5" :style="buttonStyle" v-bind="attrs" v-on="on" @click="showProjects = !showProjects">
              <div v-if="$vuetify.breakpoint.smAndUp">{{ $t("form.Project_lable") }}</div>
              <v-icon v-else size="20">mdi-list-box</v-icon>
            </v-btn>
          </template>
          <v-card style="min-width: 400px; max-height: 300px; overflow-y: auto;">
            <v-list>
              <h4 style="font-size: 18px;" class="ml-4 py-3">{{ $t('home.created_by_me') }}</h4>
              <v-list-item
                v-for="(project, index) in userProjects"
                :key="project.id"
                @click="selectProject(project)">
                <v-list-item-avatar>
                  <v-img v-if="project.project_media" :src="project.project_media" alt="project image" />
                  <v-avatar v-else size="40" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(project.name) }}</v-avatar>
                </v-list-item-avatar>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :color="project.flag"
                      small
                      class="ml-7 mt-n7 custom-btn-project btn_flag"
                      style="position: absolute;"
                    >
                      <v-icon size="10">mdi-flag-variant</v-icon>
                    </v-btn>
                  </template>
                    <span>{{ project.comment || "--" }}</span>
                </v-tooltip>
                  <v-list-item-content :class="$vuetify.breakpoint.width >= 485 ? '':'mb-6'">
                    <v-list-item-title>{{ project.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-subtitle class="justify-end d-flex mr-5" :style="$vuetify.breakpoint.width >= 485 ? '':'position: absolute; left: 70px;'">
                      <div v-if="project.role === 1">
                        <v-icon>mdi-account-cowboy-hat</v-icon>
                        <span class="text-body-2 font-weight-medium ml-1">
                          {{ $t("home.owner") }}
                        </span>
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-action>
              </v-list-item>
              
              <div v-if="!loadVisibleMyProject" class="observer" v-observer="{ nextPage: loadMoreMyProject }"></div>

              <h4 style="font-size: 18px;" class="ml-4 py-3">{{ $t('home.guest_projects') }}</h4>
              <v-list-item
                v-for="(project, index) in guestProjects"
                :key="project.id"
                @click="selectProject(project)">
                <v-list-item-avatar>
                  <v-img v-if="project.project_media" :src="project.project_media" alt="project image" />
                  <v-avatar v-else size="40" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(project.name) }}</v-avatar>
                </v-list-item-avatar>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :color="project.flag"
                      small
                      class="ml-7 mt-n7 custom-btn-project btn_flag"
                      style="position: absolute;"
                    >
                      <v-icon size="10">mdi-flag-variant</v-icon>
                    </v-btn>
                  </template>
                    <span>{{ project.comment || "--" }}</span>
                </v-tooltip>
                <v-list-item-content :class="$vuetify.breakpoint.width >= 485 ? '':'mb-6'">
                  <v-list-item-title>{{ project.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-subtitle class="justify-end d-flex mr-5" :style="$vuetify.breakpoint.width >= 485 ? '':'position: absolute; left: 70px;'">
                    <div v-if="project.role === 2">
                      <v-icon>mdi-account-star</v-icon>
                      <span class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.project_manager") }}
                      </span>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-action>
              </v-list-item>

              <div v-if="!loadVisibleGuestProject" class="observer" v-observer="{ nextPage: loadMoreGuestProject }"></div>

            </v-list>
          </v-card>
        </v-menu>
        <v-col cols="auto" v-if="SelectUserStat" class="ml-4 mt-4 py-0 pr-0 pa-2 d-flex align-center" 
          :style="$vuetify.breakpoint.width <= 625 ? 
          'border: 2px solid #ff7a00; border-radius: 10px; position: absolute; z-index: 100; top: -2px; right: 55px'
          :'border: 2px solid #ff7a00; border-radius: 10px; z-index: 100; position: absolute; top: -2px; right: 55px'"
        >
          <t-avatar :user="selectUserInfo" />
          <label disabled outlined small dense class="ml-3">
            {{ selectUserInfo.email.slice(0, 15) + (selectUserInfo.email && selectUserInfo.email.length > 15 ? '...' : '') }}
          </label>
          <v-btn icon>
            <v-icon color="red" @click="AllUsers">mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="projectD && !SelectUserStat" class="mr-0 ml-4 mt-4 py-0 pr-0 pa-2 d-flex align-center" :style="$vuetify.breakpoint.width <= 625 ? 
          'border: 2px solid #ff7a00; border-radius: 10px; position: absolute; z-index: 100; top: -2px; right: 55px'
          :'border: 2px solid #ff7a00; border-radius: 10px; z-index: 100; position: absolute; top: -2px; right: 55px'"
        >
          <v-avatar v-if="projectD.project_media" size="27">
            <v-img :src="projectD.project_media" alt="project image" />
          </v-avatar>
          <v-avatar v-else size="27" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(projectD.name) }}</v-avatar>
          <label disabled outlined small dense class="mx-3">{{ projectD.name.slice(0, 15) + (projectD.name && projectD.name.length > 15 ? '...' : '') }}</label>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :color="projectD.flag"
                small
                class="ml-5 mt-n5 custom-btn-project btn_flag"
                style="position: absolute;"
              >
                <v-icon size="10">mdi-flag-variant</v-icon>
              </v-btn>
            </template>
              <span>{{ projectD.comment || "--" }}</span>
          </v-tooltip>
          <v-btn icon>
            <v-icon color="red" @click="AllUsers">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </div>
      <v-card-text class="align-center justify-center">
        <div v-if="!SelectUserStat && !projectD">
          <statisitc-select-time-dialog
            :visible="timeUsersAll"
            @close="timeUsersAll = false"
            v-if="timeUsersAll"
            :UsersAll="'allUsersStart'"
            @UserClick="ClickSelectUser"
          />
        </div>
        <div v-if="selectUserInfo == null">
          <statisitc-select-time-dialog
            :visible="timeProjectDialog"
            :key="projectD.id"
            @close="timeProjectDialog = false"
            v-if="timeProjectDialog"
            :project="projectD"
            @UserClick="ClickSelectUser"
          />
        </div>
        <div v-if="selectUserInfo != null">
          <statisitc-select-time-dialog
            :visible="timeUserDialog"
            :key="selectUserInfo.id ? selectUserInfo.id : selectUserInfo.user_id"
            @close="timeUserDialog = false"
            v-if="timeUserDialog"
            :SelectUser="SelectUserStat"
            :selectUserName="selectUserInfo.username ? selectUserInfo.username : selectUserInfo.user_username"
            :selectUserID="selectUserInfo.id ? selectUserInfo.id : selectUserInfo.user_id"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import { validationForEmail } from "@/mixins/helpers";
import { email, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import notifications from "@/mixins/notifications";
import projectsService from "@/services/request/projects/projectsService";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import userMixin from "@/mixins/user";
import EventBus from "@/events/EventBus";
import {mapGetters} from "vuex";
import TimePicker from "@/components/UI/timePicker.vue";
import statisitcSelectTimeDialog from "./statisitcSelectTimeDialog.vue";

export default {
  mixins: [dialogMixin, validationMixin, notifications, userMixin],
  data: () => ({
    loading: false,
    showProjects: false,
    userProjects: [],
    guestProjects: [],
    projectD: null,
    timeProjectDialog: false,
    timeUserDialog: false,
    usersList: [],
    showUsersMenu: false,
    SelectUserStat: "",
    selectUserInfo: null,
    AllUsersProject: [],
    timeUsersAll: false,
    
    loadVisibleMyProject: false,
    loadVisibleGuestProject: false,
    page_MyProject: 0,
    page_GuestProject: 0,
  }),
  validations: {
    addUserForm: {
      email: { required, email },
    },
  },
  props: {
    location: {
      required: false,
      default: "project",
    },
  },
  components: {
    TimePicker,
    ConfirmLeftDialog,
    ConfirmDialog,
    statisitcSelectTimeDialog,
  },
  mounted() {
    this.AllUsers();
  },
  methods: {
    getLetters(item) {
      let letters = "";
      if (item) {
        letters = item?.trim().charAt(0).toUpperCase();
      }
      return letters;
    },
    async ClickSelectUser(user) {
      this.selectUserInfo = user;
      this.SelectUserStat = "select";
      this.$nextTick(() => {
        this.timeUserDialog = true;
      });
    },
    selectUser(user) {
      this.SelectUserStat = "select";
      this.selectUserInfo = user;
      this.timeProjectDialog = false;
      this.timeUserDialog = true;
    },
    async usersListData() {
      const UsersManaged = await projectsService.getUsersManaged();
      this.usersList = UsersManaged;
    },
    async fetchUsers() {
      this.loading = true;
      try {
        this.usersList = await projectsService
          .getUsers(
            this.location === "board" ? this.project.project_id : this.project.id)
          .then((users) => users.sort((a, b) => a.role - b.role));
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.loading = false;
      }
    },
    selectProject(project) {
      this.projectD = project;
      this.selectUserInfo = null;
      this.SelectUserStat = "";
      this.timeProjectDialog = true;
    },
    async AllUsers() {
      this.projectD = null;
      this.selectUserInfo = null;
      this.SelectUserStat = "";
      this.timeUsersAll = true;
      this.timeProjectDialog = false;
      this.timeUserDialog = false;
    },
    loadMoreMyProject() {
      this.page_MyProject++;
      this.MyProjects(this.page_MyProject);
    },
    loadMoreGuestProject() {
      this.page_GuestProject++;
      this.GuestProjects(this.page_GuestProject);
    },
    async MyProjects(morePage) {
      try {
        const params = {
          page: morePage,
          per_page: 8,
          owner: true,
        };
        const newMyProjects = await projectsService.getList(params);
        if (newMyProjects.length) {
          this.userProjects = [...this.userProjects, ...newMyProjects];
        }
        if(newMyProjects.length < 8) {
          this.loadVisibleMyProject = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async GuestProjects(morePage) {
      try {
        const params = {
          page: morePage,
          per_page: 8,
          owner: false,
        };
        const newGuestProjects = await projectsService.getList(params);
        if (newGuestProjects.length) {
          this.guestProjects = [...this.guestProjects, ...newGuestProjects.filter(project => project.role !== 3)];
        }
        if(newGuestProjects.length < 8) {
          this.loadVisibleGuestProject = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    onCancel() {
      localStorage.setItem("time_created_period", JSON.stringify(""));
      this.$emit("close");
    },
  },
  computed: {
    buttonStyle() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return this.$vuetify.breakpoint.width < 600
          ? { zIndex: 100, position: 'absolute', top: '45px', right: '30px', minWidth: '35px', maxWidth: '35px' }
          : { zIndex: 100, position: 'absolute', top: '45px', right: '30px' };
      } else {
        return { zIndex: 100, position: 'absolute', top: '5px', left: '370px', minWidth: '75px', maxWidth: '75px' };
      }
    }
  },
  watch: {
    projectD: {
      handler(newProject) {
      },
      deep: true,
    },
  },
};
</script>
