import _ from "lodash";

// eslint-disable-next-line import/prefer-default-export
export const uniqueByUUID = array => _.uniqBy(array, "id");
// eslint-disable-next-line consistent-return
export function noEngTextPasted(e, isNumber = false) {
  const value = [...e.clipboardData.getData("Text")];
  const regex = isNumber ? /^[+0-9 ]+$/ : /^[.\p{L}\d\s\-ґїҐЇіІ]+$/u;
  value.forEach(char => {
    if (regex.test(char)) {
      this.onEngText = true;
    } else {
      e.preventDefault();
      this.onEngText = false;
    }
  });
}

export function noEngText(event, isKeyDown = true) {
  if (event.metaKey) return
  let char = ''
  if (event.keyCode) {
    char = String.fromCharCode(event.keyCode);
  } else {
    char = event
  }

  if (/[А-ЯҐЄІЇа-яґєії\s\-']/u.test(char)) {
    this.onEngText = true;
  } else {
    if (isKeyDown) {
      event.preventDefault()
    }
    this.onEngText = false;
  }
}
export function noEngTextNumber(e) {
  if (e.metaKey) return
  const char = String.fromCharCode(e.keyCode);
  if (/^[.,а-яА-ЯёЁіІїЇэЭєЄ0-9 ]+$/.test(char) || e.keyCode == 13) {
    this.onEngText = true;
  } else {
    e.preventDefault();
    this.onEngText = false;
  }
}

export function onlyNumber(e, dot) {
  if (e.metaKey) return
  const char = String.fromCharCode(e.keyCode);
  const regex = dot ? /[.0-9]+$/ : /[0-9]+$/
  if (regex.test(char)) return true;
  e.preventDefault();
}

// Validation Refactoring

// For name (Cyrillic,  "-" , " ' ")

let previousChar = '';

export function validationForName(e) {
  const regex = /[А-ЯҐЄІЇа-яґєіїa-zA-Z\s\-']/u;
  const char = String.fromCharCode(e.keyCode || e.charCode);
  const cursorPosition = e.target.selectionStart;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    if (pastedText.startsWith("'") || pastedText.startsWith("-")) {
      e.preventDefault();
      return;
    }
    for (let i = 0; i < pastedText.length; i++) {
      const char = pastedText.charAt(i);
      if (!regex.test(char)) {
        e.preventDefault();
        return;
      }
    }
  } else {
    if ((char === '-' && e.target.value.slice(cursorPosition - 1, cursorPosition) === "'") ||
      (char === "'" && e.target.value.slice(cursorPosition - 1, cursorPosition) === '-')) {
      e.preventDefault();
    } else if ((cursorPosition === 0 && (char === "'" || char === "-")) ||
      ((char === '-' || char === "'") && (e.target.value.slice(cursorPosition - 1, cursorPosition) === '-' ||
        e.target.value.slice(cursorPosition - 1, cursorPosition) === "'"))) {
      e.preventDefault();
    } else if (!regex.test(char)) {
      e.preventDefault();
    }
  }
}


// For surname (Cyrillic,  "-" , " ' ")

export function validationForSurname(e) {
  const regex = /[А-ЯҐЄІЇа-яґєіїa-zA-Z\s\-']/u;
  const char = String.fromCharCode(e.keyCode || e.charCode);
  const cursorPosition = e.target.selectionStart;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    if (pastedText.startsWith("'") || pastedText.startsWith("-")) {
      e.preventDefault();
      return;
    }
    for (let i = 0; i < pastedText.length; i++) {
      const char = pastedText.charAt(i);
      if (!regex.test(char)) {
        e.preventDefault();
        return;
      }
    }
  } else {
    if ((char === '-' && e.target.value.slice(cursorPosition - 1, cursorPosition) === "'") ||
      (char === "'" && e.target.value.slice(cursorPosition - 1, cursorPosition) === '-')) {
      e.preventDefault();
    } else if ((cursorPosition === 0 && (char === "'" || char === "-")) ||
      ((char === '-' || char === "'") && (e.target.value.slice(cursorPosition - 1, cursorPosition) === '-' ||
        e.target.value.slice(cursorPosition - 1, cursorPosition) === "'"))) {
      e.preventDefault();
    } else if (!regex.test(char)) {
      e.preventDefault();
    }
  }
}



// For email (Latin, Numbers, "." "+" , "@", "-", "_")
export function validationForEmail(e) {
  const char = String.fromCharCode(e.keyCode);
  const regex = /[a-zA-Z0-9@+.\-_]/;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      const char = pastedText.charAt(i);
      if (!regex.test(char)) {
        e.preventDefault();
        return;
      }
    }
  }

  else if (char === "'" || !regex.test(char)) {
    e.preventDefault();
    return false;
  }

  return true;
}

// For nickname (Cyrillic, Latin,  "-" , " ' ")
export function validationForNickName(e) {
  const char = String.fromCharCode(e.keyCode || e.charCode);
  const regex = /[a-zA-Z0-9\sА-ЯҐЄІЇа-яґєії]/u;
  this.onEngText = true;
  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      const char = pastedText.charAt(i);
      if (!regex.test(char)) {
        e.preventDefault();
        return;
      }
    }
  }

  else if (regex.test(char)) {
    if ((char === '-' || char === "'") && (previousChar === '-' || previousChar === "'")) {
      e.preventDefault();
    }
    previousChar = char;
    return true;
  } else {
    e.preventDefault();
    this.onEngText = false;
  }
}

// For phone number (Numbers, "+")
export function validationForPhoneNumber(e, dot) {
  if (e.metaKey) return
  const char = String.fromCharCode(e.keyCode);
  const regex = dot ? /[.+0-9]+$/ : /[+0-9]+$/;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      const char = pastedText.charAt(i);
      if (!regex.test(char)) {
        e.preventDefault();
        return;
      }
    }
  }

  else if (char === "'" || !regex.test(char)) {
    e.preventDefault();
    return false;
  }

  return true;
}
// For numbers (Numbers)
export function validationForNumbers(e) {
  if (e.metaKey) return
  const char = String.fromCharCode(e.keyCode);
  const regex = /[0-9]+$/;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      const char = pastedText.charAt(i);
      if (!regex.test(char)) {
        e.preventDefault();
        return;
      }
    }
  }

  else if (char === "'" || !regex.test(char)) {
    e.preventDefault();
    return false;
  }

  return true;
}

// For warehouses (Cyrillic, Latin, Numbers, "-")
export function validationForWarehouses(e) {
  if (e.metaKey) return;
  const char = String.fromCharCode(e.keyCode);
  const regex = /[0-9а-яА-Яa-zA-Z'-ієїІЄЇ]$/;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      const char = pastedText.charAt(i);
      if (!regex.test(char)) {
        e.preventDefault();
        return;
      }
    }
  } else if (char === "'" || !regex.test(char)) {
    e.preventDefault();
    return false;
  }

  return true;
}
// For passwords (Without Cyrillic)
export function validationForPassword(e) {
  if (e.metaKey) return true;

  const char = String.fromCharCode(e.keyCode);
  const regex = /^[^\p{Script=Cyrillic}]*$/u;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    if (!regex.test(pastedText)) {
      e.preventDefault();
      return false;
    }
  } else if (!regex.test(char)) {
    e.preventDefault();
    return false;
  }

  return true;
}
// For cities (Cyrillic, " - ", " ' ")
export function validationForCities(e) {
  if (e.metaKey) return;
  const char = String.fromCharCode(e.keyCode);
  const regex = /^[а-яА-Я' ЇїҐґіІЄє-]*$/;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    if (!regex.test(pastedText)) {
      e.preventDefault();
      return false;
    }
  } else if (char !== "'" && !regex.test(char)) {
    e.preventDefault();
    return false;
  }

  return true;
}

// For streets (Cyrillic, Numbers, ".", " - ")
export function validationForStreets(e) {
  const char = String.fromCharCode(e.keyCode);
  const regex = /[0-9а-яА-ЯіІїЇєЄґҐ.]/;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      const char = pastedText.charAt(i);
      if (!regex.test(char)) {
        e.preventDefault();
        return;
      }
    }
  } else if (char === "'" || char === '-' || char === ' ') {
    // Дозволяємо введення апострофа, дефісу і пробілу
    return true;
  } else if (!regex.test(char)) {
    e.preventDefault();
    return false;
  }

  return true;
}

// For department (Without Latin)
export function validationForDepartment(e) {
  if (e.metaKey) return;
  const char = String.fromCharCode(e.keyCode);
  const regex = /^[^a-zA-Z]*$/;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    if (!regex.test(pastedText)) {
      e.preventDefault();
      return;
    }
  } else if (char === "'" || !regex.test(char)) {
    e.preventDefault();
    return false;
  }

  return true;
}

// For titles and search fields (Cyrillic,Latin, Numbers, "-", " . ", " ")
export function validationForTitle(e) {
  const char = String.fromCharCode(e.keyCode);
  const regex = /[a-zA-Z0-9\u0400-\u04FF\u0500-\u052F\s\-\.]/;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      const char = pastedText.charAt(i);
      if (!regex.test(char)) {
        e.preventDefault();
        return;
      }
    }
  }

  else if (char === "'" || !regex.test(char)) {
    e.preventDefault();
    return false;
  }

  return true;
}

// For barcodes (Latin,Numbers, "-", ",")
export function validationForBarcode(e) {
  const char = String.fromCharCode(e.keyCode);
  const regex = /[a-zA-Z0-9\-,]/;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      const char = pastedText.charAt(i);
      if (!regex.test(char)) {
        e.preventDefault();
        return;
      }
    }
  }

  else if (char === "'" || !regex.test(char)) {
    e.preventDefault();
    return false;
  }

  return true;
}

// For prices, net weight, width, height, length (Numbers ,  "."  ( two numbers after dot .xx ))
export function validationForPrice(e) {
  const isPaste = e.type === 'paste';
  const char = isPaste ? null : String.fromCharCode(e.keyCode);
  const input = isPaste ? (e.clipboardData || window.clipboardData).getData('text') : e.target.value;

  if (!isPaste && !/\d|\./.test(char)) {
    e.preventDefault();
    return false;
  }

  if (isPaste && !/^\d*\.?\d*$/.test(input)) {
    e.preventDefault();
    return false;
  }

  if (!isPaste && char === '.' && (input.includes('.') || input === '')) {
    e.preventDefault();
    return false;
  }

  if (!isPaste && char === '.' && input === '.') {
    e.preventDefault();
    return false;
  }

  const parts = input.split('.');
  const integerPart = parts[0];
  const decimalPart = parts[1];

  if (decimalPart && decimalPart.length >= 2) {
    e.preventDefault();
    return false;
  }
}

// For houses and flats ( Cyrillic, Numbers, "/" )
export function validationForHouses(e) {
  const char = String.fromCharCode(e.keyCode);
  const regex = /[0-9/а-яА-ЯіІїЇєЄґҐ]/i;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    if (!regex.test(pastedText)) {
      e.preventDefault();
    }
  } else if (char === "'" || !regex.test(char)) {
    e.preventDefault();
    return false;
  }

  return true;
}

// For timePicker
export function validationForTimePicker(e) {
  const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

  if (e.type === 'paste') {
    const pastedText = (e.clipboardData || window.clipboardData).getData('text');
    if (!timePattern.test(pastedText)) {
      e.preventDefault();
    }
  } else {
    const char = String.fromCharCode(e.keyCode);
    const regex = /[0-9:]/;

    if (char === "'" || !regex.test(char)) {
      e.preventDefault();
    }

    if (e.target.value.length === 2 && e.keyCode !== 8 && e.keyCode !== 46) { // Add colon automatically after 2 digits
      e.target.value += ':';
    }
  }

  return true;
}
