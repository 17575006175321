import * as getterTypes from "./types/getters";
// eslint-disable-next-line import/no-cycle

export default {
  [getterTypes.GET_MENU_POSITION]: (state) => state.menuPosition,
  [getterTypes.GET_SHOW_500_ERROR_DIALOG]: (state) => state.show500ErrorDialog,
  [getterTypes.GET_USER_DATA]: (state) => state.user,
  [getterTypes.GET_PERMISSIONS]: (state) => {
    const isOwner = () => state.user?.role === 1;
    const isPM = () => state.user?.role === 2;
    const isStaff = () => state.user?.role === 3;
    const all = () => isOwner() || isPM() || isStaff();
    return {
      can_see_home_page: all(),
      can_create_board: isPM() || isOwner(),
      can_edit_board: isPM() || isOwner(),
      can_add_users: isPM() || isOwner(),
      can_manage_links: isPM() || isOwner(),
    };
  },
};
