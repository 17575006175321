import { mapActions, mapGetters } from "vuex";
import * as actionTypes from "@/store/modules/comments/types/actions";
import * as getterTypes from "@/store/modules/comments/types/getters";

export default {
  methods: {
    ...mapActions("comments", {
      setDetailsStatus: actionTypes.SET_DETAILS_STATUS,
    }),
  },
  computed: {
    ...mapGetters("comments", {
      detailsStatus: getterTypes.GET_DETAILS_STATUS,
    }),
  },
};
