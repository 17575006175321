import { mapActions, mapGetters } from 'vuex';
import * as getterTypes from '@/store/modules/auth/types/getters';
import * as actionTypes from '@/store/modules/auth/types/actions';

export default {
  methods: {
    ...mapActions('auth', {
      logOut: actionTypes.LOG_OUT,
    }),
    getUserProperty(property) {
      if (this.getUser) {
        if (this.getUser[property]) return this.getUser[property];
      }
      return '';
    },
  },
  data: () => ({
    owner: 'owner',
    pm: 'pm',
    staff: 'staff',
  }),
  computed: {
    ...mapGetters('auth', {
      getUser: getterTypes.GET_USER_DATA,
      permissions: getterTypes.GET_PERMISSIONS,
    }),
  },
};
