<template>
  <VDialog content-class="cross__dialog" v-model="visibility" max-width="500" :transition="$dialogTransition">
    <VCard>
      <!-- <v-fade-transition>
        <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="orange"
        ></v-progress-linear>
      </v-fade-transition> -->
      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <VCardTitle
        class="headline"
        :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-alert text dense :type="typeSuccess" class="w-100 v-custom-alert">
          <h3>{{ $t("form.taskDescriptionDialog.CreateMulti.lable_text") }}</h3>
        </v-alert>
      </VCardTitle>
      <VCardText>
        <span
          class="text-left d-block"
          :class="{ 'text-caption': $vuetify.breakpoint.xs }"
        >
          <p>
            {{ $t("form.taskDescriptionDialog.CreateMulti.comment_text_one") }} (<slot name="description" />). {{ $t("form.taskDescriptionDialog.CreateMulti.comment_text_two") }}
          </p>
        </span>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="6">
            <VBtn
              class="w-100"
              color="orange"
              style="text-transform: none;"
              block
              @click="OneTaskP"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
            <div>
              {{ $t("form.taskDescriptionDialog.CreateMulti.btn_create_one") }}
             </div>
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn
              block
              class="w-130 green"
              dark
              style="text-transform: none;"
              @click="onConfirm"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
            <div>
              {{ $t("form.taskDescriptionDialog.CreateMulti.btn_create_multi") }} (<slot name="description" />)
             </div>
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: "CreateMultiTasks",
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    data: {
      required: false,
    },
    typeSuccess: {
      required: false,
      default: "success",
    },
  },
  methods: {
    onConfirm() {
      this.loading = true;
      this.$emit("MultiTasks", this.data);
      this.loading = false;
    },
    OneTaskP() {
      this.$emit("OneTask");
    },
    onCancel() {
      this.$emit("close");
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>

<style lang="scss">
.v-custom-alert {
  .v-alert__wrapper {
    i {
      margin-top: 4px;
    }
  }
}
</style>
