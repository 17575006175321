<template>
  <v-btn
    :block="large"
    small
    :color="tag.bg"
    class="mr-1 mb-1"
    :class="textColor(tag.bg) ? 'text-white' : 'text-black'"
    :ripple="false"
    >{{ tag.name }}</v-btn
  >
</template>

<script>
export default {
  props: {
    tag: {
      required: true,
    },
    large: {
      required: false,
      default: false,
    },
  },
  methods: {
    textColor(color) {
      switch (color) {
        case "#FFC107": {
          return false;
        }
        default: {
          return true;
        }
      }
    },
  },
};
</script>
