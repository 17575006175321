<template>
  <v-dialog
    v-model="visibility"
    max-width="500"
    content-class="cross__dialog"
    :persistent="changed"
    :transition="$dialogTransition"
    @click:outside="showConfirmDialog"
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
          v-if="loading"
          absolute
          top
          indeterminate
          color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title class="justify-center">
        {{ type == "create" ? $t('form.testCases.createTestSuite') : $t('form.testCases.editTestSuite') }}
      </v-card-title>
      <v-card-text class="pb-0 px-4">
        <v-row no-gutters>
          <v-col cols="12"
            ><v-text-field
              :label="$t('table.title')"
              v-model="inputSuit.name"
              hide-details
              :maxlength="128"
              color="orange"
              outlined
              dense
          /></v-col>
          <v-col cols="12" class="py-1 px-0" v-if="suit?.name && type === 'create'">

            {{ $t('form.testCases.willBeCreatedIn') }}<v-icon>{{ suit.icon }}</v-icon> {{ suit.name }}</v-col
          >
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <v-btn
              dark
              block
              class="br-5 text-transform-none"
              @click="showConfirmDialog"
              >{{ $t("btn.cancel") }}</v-btn
            >
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn
              v-if="type === 'edit'"
              block
              class="btn-orange-bg text-transform-none br-5"
              :disabled="!changed"
              :loading="loading"
              @click="onUpdate()"
              >{{ $t("btn.update") }}</v-btn
            >
            <v-btn
              v-if="type === 'create'"
              block
              class="btn-orange-bg text-transform-none br-5"
              :disabled="!changed"
              :loading="loading"
              @click="onCreate()"
              >{{ $t("btn.create") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onCancel"
    />
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import testCasesService from "@/services/request/testCases/testCasesService";
import EventBus from "@/events/EventBus";
import notification from "@/mixins/notifications";
import testSuitsCasesMixin from "@/mixins/testSuitsCasesMixin";

export default {
  mixins: [dialogMixin, validationMixin, notification, testSuitsCasesMixin],
  data: () => ({
    loading: false,
    changed: false,
    visibleConfirm: false,
    inputSuit: {},
    copySuit: {},
  }),
  validations: {
    inputSuit: {
      name: { required },
    },
  },
  props: {
    type: {
      required: true,
    },
    suit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    projectId: {
      required: true,
    },
  },
  components: {
    ConfirmLeftDialog,
  },
  async mounted() {
    this.onMounted();
  },
  methods: {
    async onMounted() {
      if(this.type === 'edit') {
        this.inputSuit = { ...this.suit };
        this.copySuit = { ...this.inputSuit };
      }
    },
    onCancel() {
      this.$v.$reset();
      this.$emit("close");
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onCancel();
    },
    async onCreate() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("name", this.inputSuit.name);
        if (this?.suit?.id) {
          formData.append("parent_id", this?.suit?.id);
        }
        const item = await testCasesService.createTestSuit(
          this.projectId,
          formData
        );
        if(Object.keys(this?.suit)?.length > 0){
          await this.fetchChildren(this?.suit);
        } else {
          EventBus.$emit('updateTreeAfterAdding', item.data);
        }
        this.setSuccessNotification(this.$t("profile.successfully_created"));
        this.visibility = false;
      } catch (e) {
        console.log(e.message);
      } finally {
        this.loading = false;
      }
    },
    async onUpdate() {
      this.loading = true;
      try {
        const formData = new FormData();
        const changedData = this.$getChangedData(this.inputSuit, this.suit);
        changedData.forEach((data) => {
          formData.append(data.field, data.value);
        });
        formData.append("_method", 'patch');
        await testCasesService.updateTestSuit(
          this.projectId,
          this.inputSuit.id,
          formData
        );
        await this.onUpdateSuit(this?.suit);
        this.setSuccessNotification(this.$t("profile.successfully_updated"));
        this.visibility = false;
      } catch (e) {
        console.log(e.message);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    inputSuit: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copySuit, this.inputSuit);
      },
    },
  },
};
</script>
