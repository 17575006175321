export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
  SYSTEM: "system",
};

export const DEFAULT_STORAGE = {
  ui: {
    isDark: false,
  },
};
