import cardsService from "@/services/request/cards/cardsService";
import notifications from "@/mixins/notifications";

export default {
  mixins: [notifications],
  methods: {
    async deleteFromCard(user) {
      try {
        const formData = new FormData();
        formData.append("email", user.email);
        await cardsService.deleteUserFromCard(
          this.board.project_id,
          this.board.id,
          this.task.list_id,
          this.task.id,
          formData
        );
        const searchItem = this.inputTask.assigned_users.findIndex(
          (item) => item.id === user.id
        );
        this.inputTask.assigned_users.splice(searchItem, 1);
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    usersCardClose(type, users = []) {
      if (type === "create") {
        users = users.map((item) => item.user);
        this.inputTask.assigned_users = users;
      }
      this.usersCard = false;
    },
    async deleteUserFromCard(user) {
      try {
        const formData = new FormData();
        formData.append("email", user.email);
        await cardsService.deleteUserFromCard(
          this.board.project_id,
          this.board.id,
          this.task.list_id,
          this.task.id,
          formData
        );
        const searchItem = this.task.assigned_users.findIndex(
          (item) => item.email == user.email
        );
        this.task.assigned_users.splice(searchItem, 1);
        if (typeof this.changeTask === "function") {
          this.changeTask();
        }
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    async addUserToCard(user) {
      try {
        const formData = new FormData();
        formData.append("email", user.email);
        await cardsService.addUserToCard(
          this.board.project_id,
          this.board.id,
          this.task.list_id,
          this.task.id,
          formData
        );
        this.task.assigned_users.push(user);
        if (typeof this.changeTask === "function") {
          this.changeTask();
        }
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
  },
};
