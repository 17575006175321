import { mapActions, mapGetters } from "vuex";
import * as actionTypes from "@/store/modules/activeList/types/actions";
import * as getterTypes from "@/store/modules/activeList/types/getters";

export default {
  methods: {
    ...mapActions("activeList", {
      setActiveList: actionTypes.SET_ACTIVE_LIST,
      setActiveListTab: actionTypes.SET_ACTIVE_LIST_TAB,
    }),
  },
  computed: {
    ...mapGetters("activeList", {
      activeList: getterTypes.GET_ACTIVE_LIST,
      activeListTabs: getterTypes.GET_ACTIVE_LIST_TAB,
    }),
  },
};
