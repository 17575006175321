<template>
  <div>
    <v-dialog
      v-model="visibility"
      transition="scroll-y-reverse-transition"
      fullscreen
    >
      <v-card class="testCases">
        <v-fade-transition>
          <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="orange"
          ></v-progress-linear>
        </v-fade-transition>
        <div class="testCases-closeBtn">
          <v-btn plain large icon elevation="0" @click="onCancel()">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="justify-center">
          {{ $t("testCases.test_cases") }}
          <div class="testCases-buttons" v-if="!hiddenBtnSuit">
            <v-btn class="btn-orange-bg ml-2" @click="onCreateTestSuit()">
              <v-icon small class="mr-1">mdi-folder-plus</v-icon>
              {{ $vuetify.breakpoint.width <= 1150 ? "" : "TestSuit" }}</v-btn>
          </div>
          <div class="testCases-buttons" v-else>
            <v-btn color="orange" outlined @click="GoBackSuits">
              <v-icon left>mdi-arrow-left</v-icon>
              {{ $t("btn.back")}}
            </v-btn>
          </div>
        </v-card-title>
        <v-divider class="mb-1 mx-4" />
        <div class="testCases-container">
          <testCasesTree
            @setLoading="setLoading"
            @openTestCase="openTestCase"
            :projectId="projectId"
            :opened="opened"
            :actived="actived"
            @IsMobileBtn="IsMobileBtnBack"
            :gobacksuits="hiddenBtnSuit"
            :type="type"
            :task="task"
          />
          <testCasePanel :testCase="testCase" :projectId="projectId"/>
        </div>
      </v-card>
    </v-dialog>
    <testSuitDialog
      v-if="testSuitDialog.visible"
      :visible="testSuitDialog.visible"
      :suit="testSuitDialog.item"
      :type="testSuitDialog.type"
      :projectId="projectId"
      @close="closeDialog('testSuit')"
    />
    <testCaseDialog
      v-if="testCaseDialog.visible"
      :visible="testCaseDialog.visible"
      :caseIn="testCaseDialog.item"
      :type="testCaseDialog.type"
      :projectId="projectId"
      @close="closeDialog('testCase')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";
import notifications from "@/mixins/notifications";
import dialogMixin from "@/mixins/dialogMixin";
import testCasesTree from "./testCasesTree.vue";
import testSuitDialog from "./testSuitDialog.vue";
import testCasePanel from "./testCasePanel.vue";
import testCaseDialog from "./testCaseDialog.vue";

export default {
  mixins: [notifications, dialogMixin],
  data: () => ({
    loading: false,
    testSuitDialog: {
      visible: false,
      item: {},
      type: "",
    },
    hiddenBtnSuit: false,
    testCaseDialog: {
      visible: false,
      item: {},
      type: "",
    },
    testCase: {},
    opened: [],
    actived: [],
  }),
  props: {
    projectId: {
      required: false,
    },
    type: {
      required: true
    },
    task: {
      required: false
    }
  },
  components: {
    testCasesTree,
    testSuitDialog,
    testCasePanel,
    testCaseDialog,
  },
  methods: {
    GoBackSuits() {
      this.hiddenBtnSuit = false;
    },
    IsMobileBtnBack(BtnBack) {
      this.hiddenBtnSuit = true;
    },
    openTestCase(testCase) {
      this.testCase = testCase;
    },
    onCancel() {
      this.$emit("close");
    },
    setLoading(loadingStatus) {
      this.loading = loadingStatus;
    },
    closeDialog(type) {
      type === "testCase"
        ? (this.testCaseDialog.visible = false)
        : (this.testSuitDialog.visible = false);
    },
    onCreateTestSuit() {
      this.testSuitDialog.type = "create";
      this.testSuitDialog.item = {};
      this.testSuitDialog.visible = true;
    },
  },
  computed: {
    ...mapGetters("auth", {
      menuPosition: getterTypes.GET_MENU_POSITION,
    }),
  },
};
</script>

<style lang="scss">
.testCases {
  &-dialog {
    border-radius: 0px;
    min-height: calc(100% - 55px) !important;
    overflow-y: unset !important;
    position: relative;
    top: 55px;
    left: 120px;
    width: calc(100% - 236px) !important;
    right: 0px;
  }
  &-dialog--mini {
    border-radius: 0px;
    min-height: calc(100% - 55px) !important;
    overflow-y: unset !important;
    position: relative;
    top: 55px;
    width: 100% !important;
    right: 0px;
  }
  &-closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    transition: 0.2s ease;
    &:active {
      transform: scale(0.9);
    }
  }
  &-container {
    display: flex;
    min-height: 90svh !important;
    padding: 0 12px;
  }
  &-buttons {
    position: absolute;
    left: 16px;
    top: 16px;
  }
}
</style>
